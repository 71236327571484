import React from 'react';

export const Score = (props: { color: string }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 33 32"
      version="1.1"
      xmlSpace="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Score</title>
      <g
        id="Page-Layout"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="iPhone-SE-&amp;-Android-(Smallest)"
          transform="translate(-34.000000, -500.000000)"
        >
          <g id="Score" transform="translate(11.000000, 500.000000)">
            <g id="Group" transform="translate(23.400000, 0.000000)">
              <polygon id="Path" points="0 0 31.2 0 31.2 31.2 0 31.2"></polygon>
              <polygon
                id="Path"
                fill={props.color}
                stroke="#3D4960"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="15.6 23.075 7.5764 27.2935 9.1091 18.3586 2.6091 12.0315 11.5791 10.7315 15.5909 2.6026 19.6027 10.7315 28.5727 12.0315 22.0727 18.3586 23.6054 27.2935"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
