import { SmallBlock } from '../components/SmallBlock';

export type LandingCombo = {
  heading: string;
  subtext: string;
};

export const LandingHeroCombos: LandingCombo[] = [
  {
    heading: 'Blazing fast?',
    subtext: 'Challenge your friends to a game of multiplayer Sudoku.',
  },
  {
    heading: 'Sudoku, Together.',
    subtext: 'Race against your friends. Do it for glory.',
  },
  {
    heading: 'Meet UsDoku.',
    subtext: 'A unique, colorful & exhilarating Sudoku-solving experience.',
  },
  {
    heading: 'Ready, set, solve.',
    subtext:
      'Face off in a race-against-the-clock multiplayer Sudoku rollercoaster.',
  },
  {
    heading: 'Sudoku like never before.',
    subtext:
      'Face off in a race-against-the-clock multiplayer Sudoku rollercoaster.',
  },
];

export const getRandomLandingCombo = (): LandingCombo => {
  return sample(LandingHeroCombos);
};

const sample = <T>(arr: T[]): T => {
  const { floor, random } = Math;
  return arr[floor(random() * arr.length)];
};
