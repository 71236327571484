import { Plugins, StatusBarStyle } from '@capacitor/core';

const { Device, StatusBar, SplashScreen } = Plugins;

export async function setupCapacitor() {
  const info = await Device.getInfo();
  if (info.platform != 'web') {
    // for now portrait. iPads may want to not have that.
    window?.screen?.orientation?.lock('portrait-primary');

    StatusBar.setStyle({
      style: StatusBarStyle.Light,
    });
    await SplashScreen.hide();
  }
}
