import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { API } from '../../lib/API';
import CreateHomeSVG from '../../assets/create_home.svg';
import { Screen } from '../Screen';
import { AsyncButton } from '../../widgets/AsyncButton';
import { GAEvent } from '../../lib/GoogleAnalytics';
import { useQueryParam, StringParam } from 'use-query-params';
import { KVStorageEngine } from '../../lib/Engines/KVStorageEngine';
import { Ad } from '../../components/Ad';

export interface CreateProps {
  goToJoin: (code: string) => void;
  goHome: () => void;
}

export const Create: FC<CreateProps> = observer((props) => {
  const [paramDifficulty] = useQueryParam('difficulty', StringParam);
  const [paramVisibility] = useQueryParam('visibility', StringParam);
  const [paramMode] = useQueryParam('mode', StringParam);

  const [difficulty, setDifficulty] = useState<string | undefined>(
    paramDifficulty as string | undefined
  );
  const [visibility, setVisibility] = useState<string | undefined>(
    paramVisibility as string | undefined
  );
  const [mode, setMode] = useState<string | undefined>(
    paramMode as string | undefined
  );

  useEffect(() => {
    KVStorageEngine.getItem('mode').then((m) => {
      if (!mode && m) {
        setMode(m);
      }
    });
    KVStorageEngine.getItem('difficulty').then((d) => {
      if (!difficulty && d) {
        setDifficulty(d);
      }
    });
    KVStorageEngine.getItem('visibility').then((v) => {
      if (!visibility && v) {
        setVisibility(v);
      }
    });
  }, []);
  const [error, setError] = useState<string | undefined>(undefined);

  const doCreate = async (
    difficulty: string,
    visibility: string,
    mode: string
  ) => {
    const created = await API.Create(difficulty, visibility, mode);
    props.goToJoin(created.gameCode);
  };

  return (
    <Screen title={'New Game'}>
      <div className="flex flex-col items-center sm-max:px-6">
        <div
          className={'pl-24 md-max:pl-12 sm-max:pl-6 w-full h-full'}
          style={{ background: 'var(--background-grey-100)' }}
        >
          <div
            className="flex items-center text-indigo-400 no-underline hover:no-underline cursor-pointer font-bold text-2xl "
            onClick={props.goHome}
          >
            UsDoku
          </div>
        </div>
        <div className={'flex-col flex items-center'}>
          <Ad size="300x50" />
          <div className="flex items-center justify-center rounded-sm  px-20 py-12">
            <div className="h-24 w-24">
              <CreateHomeSVG />
            </div>
            <div className="flex flex-col justify-center items-left  ml-4">
              <div className="font-bold mb-1">Let's create a game</div>
              <div className={'w-56 lg-max:w-48 text-sm'}>
                Configure your settings, click <strong>Create</strong> and play
                with friends.
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={'text-sm mb-4'}>
            How <strong>difficult</strong> do you want to go?
          </div>
          <div className={'flex flex-wrap'}>
            {['easy', 'medium', 'hard', 'extreme'].map((diff) => {
              const buttonClasses =
                difficulty?.toLowerCase() === diff.toLowerCase()
                  ? `bg-orange-500 text-white`
                  : `bg-transparent text-orange-500`;
              return (
                <button
                  key={diff}
                  className={`${buttonClasses} hover:bg-orange-500 lg-max:mb-4 font-semibold hover:text-white py-2 px-4 border border-orange-500 hover:border-transparent rounded mr-4`}
                  onClick={() => {
                    setError(undefined);
                    setDifficulty(diff);
                  }}
                >
                  {titleCase(diff)}
                </button>
              );
            })}
          </div>
          <div className={'text-sm mb-4 mt-8'}>
            What game <strong>mode</strong> do you want to play?
          </div>
          <div className={'flex flex-wrap'}>
            {['original', 'hardcore'].map((m) => {
              const buttonClasses =
                mode?.toLowerCase() === m.toLowerCase()
                  ? `bg-orange-500 text-white`
                  : `bg-transparent text-orange-500`;
              return (
                <button
                  key={m}
                  className={`${buttonClasses} hover:bg-orange-500 lg-max:mb-4 font-semibold hover:text-white py-2 px-4 border border-orange-500 hover:border-transparent rounded mr-4`}
                  onClick={() => {
                    setError(undefined);
                    setMode(m);
                  }}
                >
                  {titleCase(m)}
                </button>
              );
            })}
          </div>
          <div className={'text-sm mt-8'}>
            What do want this game's <strong>visibility</strong> to be?
          </div>
          <div
            className={'text-sm mb-4 text-gray-700'}
            style={{ color: 'var(--text-grey-700)' }}
          >
            (You can change this later)
          </div>
          <div className={'flex flex-wrap'}>
            {['public', 'private'].map((vis) => {
              const buttonClasses =
                visibility?.toLowerCase() === vis.toLowerCase()
                  ? `bg-orange-500 text-white`
                  : `bg-transparent text-orange-500`;
              return (
                <button
                  key={vis}
                  className={`${buttonClasses} hover:bg-orange-500 lg-max:mb-4 font-semibold hover:text-white py-2 px-4 border border-orange-500 hover:border-transparent rounded mr-4`}
                  onClick={() => {
                    setError(undefined);
                    setVisibility(vis);
                  }}
                >
                  {titleCase(vis)}
                </button>
              );
            })}
          </div>
          <div className={'flex mt-8 justify-end'}>
            <AsyncButton
              label={'Create'}
              onClick={async () => {
                if (!difficulty) {
                  throw new Error(`Please select a difficulty`);
                }
                if (!mode) {
                  throw new Error(`Please select a game mode`);
                }
                if (!visibility) {
                  throw new Error(`Please select a visibility`);
                }

                GAEvent('game_created', { difficulty, visibility, mode });

                return doCreate(
                  difficulty.toLowerCase(),
                  visibility.toLowerCase(),
                  mode.toLocaleLowerCase()
                );
              }}
            />
          </div>

          <div className="mt-4 mb-40 flex justify-center items-center">
            <Ad size="300x250" />
          </div>
        </div>
      </div>
    </Screen>
  );
});

const titleCase = (str: string) => {
  return [str[0].toUpperCase(), str.substring(1).toLowerCase()].join('');
};
