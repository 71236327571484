import React, { Component, ReactNode } from 'react';
import { Screen } from '../Screen';
const { version } = require('../../../package.json');

export interface PrivacyProps {
  goHome: () => void;
}

export class Privacy extends Component<PrivacyProps> {
  render() {
    const { goHome } = this.props;

    return (
      <Screen title={'Lobby'}>
        <div className="flex flex-col items-center sm-max:px-4 w-full">
          <div
            className={'max-w-screen-lg min-w-screen-sm w-full h-full'}
            style={{ background: 'var(--background-grey-100)' }}
          >
            <div
              className="flex items-center text-indigo-400 no-underline hover:no-underline cursor-pointer font-bold text-2xl"
              onClick={goHome}
            >
              UsDoku
            </div>
          </div>
          {this.renderPolicy()}
          <div
            className="w-full"
            style={{ background: 'var(--background-grey-200' }}
          >
            <div className="w-full pt-16 pb-6 text-sm text-center fade-in">
              <a
                className="no-underline hover:no-underline"
                style={{ color: 'var(--text-grey-500)' }}
                href="#"
              >
                &copy; UsDoku {new Date().getFullYear()}.
                <br />
                {version}
              </a>
            </div>
          </div>
        </div>
      </Screen>
    );
  }

  renderPolicy(): ReactNode {
    return (
      <div
        className={'max-w-screen-lg min-w-screen-sm w-full my-8'}
        style={{ background: 'var(--background-grey-100)' }}
      >
        <h1 className={'text-2xl font-bold'}>Privacy Policy</h1>
        <br />
        <p className={'font-bold'}>Effective Date: 29-06-2023</p>
        <br />
        <p>
          UsDoku ("the Game") respects your privacy and is committed to
          protecting the personal information you provide to us. This Privacy
          Policy outlines the types of information we may collect, how we may
          use and share that information, and the choices you have regarding
          your personal data.
        </p>
        <br />
        <p className={'font-bold'}>1. Information we collect</p>
        <br />
        <p>
          1.1. Game Code: When you start a new game, the Game generates a unique
          game code for you to share with other players. This code is used to
          connect players in the same game session. <br />
          1.2. Name and Color: To personalize your gameplay experience, the Game
          requires you to select a name. This name does not need to be your real
          name. You will be represented by your chosen name and a randomly
          generated color in the game.
          <br /> 1.3. Google Analytics: We use Google Analytics to collect basic
          analytics information about the usage of the Game. This may include
          the IP address, approximate location, device information, gameplay
          statistics, and interactions within the Game. Google Analytics uses
          cookies and similar technologies to collect and analyze this
          information. The data collected is anonymous and does not personally
          identify you.
        </p>
        <br />
        <p className={'font-bold'}>2. Use of information</p>
        <br />
        <p>
          2.1. Game Code: The game code you generate is used solely for
          connecting players in the same game session. It is not stored or used
          for any other purpose.
          <br />
          2.2. Name and Color: Your chosen name and randomly generated color are
          used within the Game to identify and differentiate players during
          gameplay. They are not associated with any personal information and
          are not used for any other purpose.
          <br />
          2.3. Google Analytics: The information collected through Google
          Analytics is used for statistical analysis, improving the Game's
          functionality and user experience, and understanding player
          preferences and behaviors. The data is aggregated and anonymized, and
          it does not personally identify you.
        </p>
        <br />
        <p className={'font-bold'}>3. Sharing of information</p>
        <br />
        <p>
          3.1. Game Code: The game code you share with other players is used
          solely for connecting players in the same game session. It is not
          shared with any third parties or used for any other purpose.
          <br />
          3.2. Name and Color: Your chosen name and randomly generated color are
          visible to other players within the same game session. However, they
          are not associated with any personal information and do not reveal
          your identity beyond the game context.
          <br />
          3.3. Google Analytics: The information collected through Google
          Analytics is shared with Google for the purpose of analyzing player
          usage and improving the Game. This data is subject to Google's Privacy
          Policy.
        </p>
        <br />
        <p className={'font-bold'}>4. Data Security</p>
        <br />
        <p>
          We implement reasonable security measures to protect the information
          collected in the Game. However, please be aware that no method of
          transmission over the internet or electronic storage is 100% secure.
          We cannot guarantee the absolute security of your information.
        </p>
        <br />
        <p className={'font-bold'}>5. Your Choices</p>
        <br />
        <p>
          5.1. Name and Color: You have the choice to select a name and a
          randomly generated color within the Game. Please note that the name
          you choose can be seen by other players within the same game session.
          <br />
          5.2. Google Analytics: If you do not want Google Analytics to collect
          information about your usage of the Game, you can opt-out by disabling
          cookies in your browser or using Google's opt-out browser add-on.
        </p>
        <br />
        <p className={'font-bold'}>6. Children's Privacy</p>
        <br />
        <p>
          The Game does not knowingly collect personal information from
          individuals under the age of 13. If you believe we have inadvertently
          collected personal information from a child, please contact us to have
          the information removed.
        </p>
        <br />
        <p className={'font-bold'}>7. Google Analytics Data Retention</p>
        <br />
        <p>
          The data collected through Google Analytics is subject to Google's
          data retention policies. We retain the aggregated and anonymized data
          for as long as necessary to fulfill the purposes outlined in this
          Privacy Policy. Google Analytics data retention periods may vary and
          are determined by Google. For more information, please refer to
          Google's data retention policies.
        </p>
        <br />
        <p className={'font-bold'}>8. Changes to Privacy Policy</p>
        <br />
        <p>
          We reserve the right to modify or update this Privacy Policy from time
          to time. We will notify you of any material changes by posting the
          updated policy within the Game or by other means of communication.
          Your continued use of the Game after the changes indicates your
          acceptance of the revised Privacy Policy.
        </p>
        <br />
        <p>
          <p className={'font-bold'}>9. Contact Us</p>
          <br />
          <p>
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or our data practices, please contact us at
            hi@usdoku.com
          </p>
        </p>
      </div>
    );
  }
}
