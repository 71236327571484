import React, { FC } from 'react';
import { WhatsNewItem } from '../../lib/WhatsNew';

export interface WhatsNewBlockProps extends WhatsNewItem {}

export const WhatsNewBlock: FC<WhatsNewBlockProps> = (props) => {
  return (
    <div className={'flex flex-col'}>
      <div className={'flex items-start text-xl font-semibold'}>
        <i className={`${props.iconName} mr-2 text-green-600`}></i>
        <span>{props.title}</span>
      </div>
      <div
        className={'mt-2 rounded-sm p-6'}
        style={{ background: 'var(--background-grey-300)' }}
      >
        {props.description}
      </div>
    </div>
  );
};
