import React, { FC, useEffect } from 'react';
import LeftArrow from '../assets/arrow-thin-left.svg';

export interface ScreenProps {
  title?: string;
  navBar?: {
    title: JSX.Element;
    onBack?: () => void;
    confirmBack?: boolean;
    color?: 'gray' | 'blue';
    align?: 'left' | 'center';
  };
  isGameScreen?: boolean;
}

const navBarBgColorFor = (color: 'gray' | 'blue' = 'blue') => {
  if (color === 'blue') {
    return 'bg-blue-500';
  }
  return 'var(--background-grey-200)';
};

const navBarTextColorFor = (color: 'gray' | 'blue' = 'blue') => {
  if (color === 'blue') {
    return '#FFF';
  }
  return '#000';
};

export const Screen: FC<ScreenProps> = (props) => {
  const { navBar } = props;

  useEffect(() => {
    document.title = props.title
      ? `${props.title} | UsDoku | Multiplayer Sudoku`
      : 'UsDoku | Multiplayer Sudoku';
  }, [props.title]);

  return (
    <div
      className={'w-full h-full'}
      style={{ background: 'var(--background-grey-100)' }}
    >
      {navBar && (
        <nav
          className={`grid ${navBarBgColorFor(navBar.color)} ${
            navBar.onBack ? 'grid-cols-3 p-4' : 'grid-cols-1 p-4'
          } `}
        >
          {navBar.onBack ? (
            <div
              className={`flex items-center justify-start  pl-6 pr-6 text-md cursor-pointer`}
              onClick={navBar.onBack}
            >
              <LeftArrow
                height="16px"
                fill={navBarTextColorFor(navBar.color)}
              />
            </div>
          ) : (
            <div />
          )}
          <div
            className={`flex ${
              (navBar.align || 'center') === 'left'
                ? 'justify-start'
                : 'justify-center'
            } `}
            style={{
              color: navBarTextColorFor(navBar.color),
            }}
          >
            <span className="font-semibold  tracking-tight">
              {navBar.title}
            </span>
          </div>
          <div />
        </nav>
      )}
      <div className={props.isGameScreen === true ? '' : 'pt-8'}>
        {props.children}
      </div>
    </div>
  );
};
