import { action, observable, runInAction } from 'mobx';
import Toggle from 'react-toggle';

import { observer } from 'mobx-react';
import {
  fromPromise,
  IPromiseBasedObservable,
} from 'mobx-utils/lib/from-promise';
import React, { FC, useEffect, useRef, useState } from 'react';
import { SquareLoader } from 'react-spinners';
import { API, GameInfoResponse, JoinGameResponse } from '../../lib/API';
import JoinHomeSVG from '../../assets/join_home.svg';
import { Store } from '../../Stores/Store';
import { Screen } from '../Screen';
import { AsyncButton } from '../../widgets/AsyncButton';
import { PlayerIndicator } from '../../components/PlayerIndicator';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ChatPayload, Events, NotificationPayload } from '../../Stores/Events';
import { toast, ToastContainer } from 'react-toastify';
import { ChatContainer } from '../../components/ChatContainer';
import { ChatToast, NameFlaggedToast } from '../../lib/Toasts';
import { GAEvent } from '../../lib/GoogleAnalytics';
import { useQueryParam, StringParam } from 'use-query-params';
import { setSessionToken } from '../../lib/sessionToken';
import { Ad } from '../../components/Ad';

export interface JoinProps {
  code: string;
  store: Store;
  goHome: () => void;
}

export const JoinState = observable<{
  loadingPromise?: IPromiseBasedObservable<GameInfoResponse>;
}>({
  loadingPromise: undefined,
});

export const Join: FC<JoinProps> = observer((props) => {
  const state = JoinState;
  const [source] = useQueryParam('source', StringParam);

  const loadingPromise = async () => {
    return await API.Info(props.code);
  };

  const doJoinGame = action(async (name: string) => {
    const { store, code } = props;

    const me = await API.Join(
      code,
      name,
      store.isCreator,
      allowConnectFromAnotherDevice,
      source as string | undefined,
      {
        colorsEnabled: !store.settingsStore.colorsDisabled,
        notificationsEnabled: store.settingsStore.dynamicNotificationsEnabled,
      }
    );
    if (me.nameFlagged) {
      setTimeout(() => {
        toast(<NameFlaggedToast name={me.name} />, { autoClose: 6000 });
      }, 1000);
    }
    runInAction(() => {
      store.setGameCode(code);
      store.setPlayerName(me.name);
      store.isCreator = me.isCreator || false;
      setSessionToken(me.session.token);
      store.connectToGame();
    });
  });

  const doStart = () => {
    GAEvent('game_start');
    return API.Start(props.code);
  };

  const setVisibility = (visibility: string) => async () => {
    await API.UpdateVisibility(props.code, visibility);
  };

  useEffect(() => {
    props.store.events.on(
      Events.Notification,
      (payload: NotificationPayload) => {
        const { message } = payload;
        toast(message);
      }
    );
    props.store.events.on(Events.Chat, (payload: ChatPayload) => {
      const color = props.store.players.find((p) => p.name === payload.name)
        ?.color;
      toast(
        <ChatToast
          color={color || 'white'}
          message={payload.message}
          name={payload.name}
        />
      );
    });
    state.loadingPromise = fromPromise(loadingPromise());
    return () => {
      props.store.events.removeAllListeners();
    };
  }, []);

  const textInputRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState<string | undefined>(undefined);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState<boolean>(
    false
  );
  const [playerName, setPlayerName] = useState<string>(props.store.playerName);
  const [copied, setCopied] = useState<boolean>(false);
  const [
    allowConnectFromAnotherDevice,
    setAllowConnectFromAnotherDevice,
  ] = useState<boolean>(false);

  const joinLinkBox = (
    <>
      {' '}
      <CopyToClipboard
        text={`https://usdoku.com/${props.code}`}
        onCopy={() => {
          GAEvent('game_link_copied');
          setCopied(true);
        }}
      >
        <span>
          <div
            className={'text-xs uppercase font-medium text-left'}
            style={{ color: 'var(--text-grey-500)' }}
          >
            Join link {copied ? '(Copied)' : '(Tap to Copy)'}
          </div>
          <div
            className={'p-6 mb-4 text-sm'}
            style={{ background: 'var(--background-grey-300)' }}
          >
            {`https://usdoku.com/${props.code}`}
          </div>
        </span>
      </CopyToClipboard>
    </>
  );

  const shareGameBox = (gameCode: string) => {
    if (!navigator.share) {
      return <></>;
    }

    return (
      <i
        className="ri-share-line text-xl cursor-pointer hover:text-purple-400 ml-2"
        onClick={async () => {
          try {
            await navigator.share({
              title: 'UsDoku',
              text: 'Join me in an UsDoku game',
              url: props.store.shareGameLink,
            });
            GAEvent('shared_game_success');
          } catch (error) {
            GAEvent('shared_game_failure');
          }
        }}
      ></i>
    );
  };

  return (
    <Screen title={'Join'}>
      <div className="flex flex-col justify-items-center items-center sm-max:px-8">
        <div
          className={'pl-24 md-max:pl-12 sm-max:pl-6 w-full h-full'}
          style={{ background: 'var(--background-grey-100)' }}
        >
          <div
            className="flex items-center text-indigo-400 no-underline hover:no-underline cursor-pointer font-bold text-2xl "
            onClick={props.goHome}
          >
            UsDoku
          </div>
        </div>
        <div>
          {state.loadingPromise?.case({
            pending: () => (
              <div className={'flex flex-col items-center mt-4'}>
                <SquareLoader color={'#4A90E2'} />
                <h3 className={'mt-4 font-bold'}>Hang tight</h3>
                <h4>
                  We're joining <strong>{props.code}</strong>
                </h4>
              </div>
            ),
            rejected: (error) => {
              console.log(error);
              return (
                <div className={'flex-col flex items-center '}>
                  <div className="flex items-center justify-center rounded-sm px-20 py-6">
                    <div className="h-24 w-24 mr-8">
                      <JoinHomeSVG />
                    </div>
                    <div className={'flex flex-col'}>
                      <div className={'w-56  lg-max:w-48 mb-1 font-bold'}>
                        Oh no!
                      </div>
                      <div className={'w-56  lg-max:w-48 text-sm mb-1'}>
                        That was unsuccessful.
                        <div className="flex items-center ">
                          <AsyncButton
                            pull={'center'}
                            label={`Go home`}
                            onClick={async () => {
                              // NB: this resets the store.
                              window.location.href = `/`;
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className={' p-6 text-sm'}
                        style={{ background: 'var(--background-grey-300)' }}
                      >
                        <div className={'w-56  lg-max:w-48 text-sm mb-1'}>
                          {error.message}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            },
            fulfilled: (value) => {
              const supersedingGame = value.info.supersededBy;
              const status =
                {
                  completed: 'The game has completed.',
                  running:
                    'The game has already started, but you can still join.',
                  pending: "The game hasn't started yet.",
                }[value.info.status] || '';

              return (
                <div className={'flex-col flex items-center '}>
                  <Ad size="300x50" />

                  <ToastContainer
                    autoClose={2500}
                    pauseOnHover={false}
                    pauseOnFocusLoss={false}
                    style={{ paddingTop: 'env(safe-area-inset-top, 0px)' }}
                    limit={1}
                  />

                  {supersedingGame && (
                    <div
                      className="flex flex-col justify-center items-center text-center mt-4 mb-4 p-8 w-full"
                      style={{ background: 'var(--background-grey-200' }}
                    >
                      <div className="font-bold">
                        Your party has started a new game in the meantime
                      </div>
                      <div className={'w-56 lg-max:w-48 text-sm mt-4'}>
                        <AsyncButton
                          pull={'center'}
                          label={`Join ${supersedingGame}`}
                          onClick={async () => {
                            // NB: this resets the store.
                            window.location.href = `/${supersedingGame}`;
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div className="flex items-center justify-center rounded-sm px-20 py-6">
                    <div className="h-24 w-24">
                      <JoinHomeSVG />
                    </div>
                    {props.store.isConnected ? (
                      <div className="flex flex-col justify-center items-left h-full ml-4">
                        <div className="flex items-center font-bold mb-1">
                          <PlayerIndicator
                            color={props.store.myColor!}
                            playerName={props.store.playerName}
                          />
                        </div>
                        <div className={'w-56  lg-max:w-48 text-sm mb-1'}>
                          Hang tight! You'll be racing the clock to fill some
                          blocks in no time! This game is rated{' '}
                          <strong>{value.info.difficulty}</strong>.
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center items-left h-full ml-4">
                        <div className="font-bold mb-1">Let's play a game</div>
                        <div className={'w-56  lg-max:w-48 text-sm mb-1'}>
                          You're all set to join <strong>{props.code}</strong>.{' '}
                          This game is rated{' '}
                          <strong>{value.info.difficulty}</strong>. {status}{' '}
                        </div>
                      </div>
                    )}
                  </div>
                  {props.store.isConnected ? (
                    <>
                      <div className={'flex flex-col items-start'}>
                        <div
                          className={'flex items-center w-full justify-between'}
                        >
                          <div>{props.code}</div>
                          <div>
                            <ChatContainer
                              options={props.store.validChatOptions
                                .filter((c) =>
                                  ['all', 'lobby'].includes(c.where)
                                )
                                .map((c) => c.text)}
                              onSelect={(message) => {
                                return API.Chat(
                                  props.code,
                                  props.store.playerName,
                                  message
                                );
                              }}
                            />
                            {shareGameBox(props.code)}
                          </div>
                        </div>
                        {props.store.players.length > 1 ? (
                          <>
                            {joinLinkBox}
                            <div>
                              <Ad size="300x50_2" />
                            </div>
                            <div className="font-bold mb-2">
                              Your fellow players:
                            </div>
                            <div className={'grid grid-rows-1 gap-y-4'}>
                              {props.store.players
                                .filter(
                                  (p) => p.name !== props.store.playerName
                                )
                                .map((p) => {
                                  return (
                                    <div className={'flex items-center'}>
                                      <div
                                        className={'mr-2'}
                                        style={{
                                          backgroundColor: p.color,
                                          height: '24px',
                                          width: '24px',
                                          borderRadius: '2px',
                                        }}
                                      />
                                      <div>{p.name} </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </>
                        ) : (
                          <div className={'text-center'}>
                            {joinLinkBox}
                            <div className="font-bold mb-2">
                              Looks like you're the first one here.
                            </div>
                          </div>
                        )}
                      </div>
                      <>
                        {window.MAINTENANCE_IN_PROGRESS === true && (
                          <div className="flex flex-col justify-center items-center text-center mt-8 mb-4 py-8 px-8 md-max:w-5/6 bg-red-200 w-full">
                            <div className="font-bold">
                              Maintenance in progress
                            </div>
                            <div className={'text-sm mt-2'}>
                              We're currently doing some maintenance on the
                              UsDoku server. During this time you may have
                              connection issues. <strong>Don't panic,</strong>{' '}
                              your game will go on as normal as soon as the
                              connection is restored.
                            </div>
                          </div>
                        )}
                        <div className="flex flex-col justify-center md-max:w-5/6">
                          <div
                            className={'mt-4 p-8 text-sm md-max:text-center'}
                            style={{ background: 'var(--background-grey-300)' }}
                          >
                            <i className="ri-star-fill align-middle text-blue-500"></i>{' '}
                            <span className={'align-middle'}>
                              Ready to take your gaming experience to the next
                              level? Join our vibrant Discord community{' '}
                              <a
                                href="https://discord.gg/hbY5pBYSkh"
                                target="_blank"
                                className="text-blue-500"
                              >
                                <strong>here</strong>
                              </a>{' '}
                              and become a part of an exciting world where
                              UsDoku players unite, share tips, and forge
                              lasting friendships!
                            </span>
                          </div>
                        </div>

                        <div className={'flex gap-2 items-center mt-4'}>
                          <div
                            className={'flex flex-col'}
                            style={{ width: '200px' }}
                          >
                            <div className={'font-bold'}>Public game</div>
                            <div className={'text-sm'}>
                              {props.store.visibility == 'public'
                                ? 'This is a public game.'
                                : 'This is a private game'}
                            </div>
                          </div>
                          <div>
                            <Toggle
                              checked={props.store.visibility == 'public'}
                              disabled={!props.store.isCreator}
                              onChange={async (e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                if (props.store.visibility == 'public') {
                                  await setVisibility('private')();
                                } else {
                                  await setVisibility('public')();
                                }
                              }}
                            />
                          </div>
                        </div>
                      </>
                      {props.store.canStartGame && (
                        <>
                          <div
                            className="flex flex-col justify-center items-center text-center mt-8 mb-4 py-8 px-8 md-max:w-5/6 w-full"
                            style={{ background: 'var(--background-grey-200' }}
                          >
                            <div className="font-bold">
                              You have the power to start this game
                            </div>
                            <div className={'text-sm mt-2'}>
                              When your party is present and ready, hit{' '}
                              <strong>Start</strong>.
                            </div>
                            <div className={'mt-4'}>
                              <AsyncButton label={'Start'} onClick={doStart} />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        className={'flex flex-col items-start'}
                        style={{ width: '300px' }}
                      >
                        <div className={'text-sm my-2'}>Nickname</div>
                        <div className={'flex'}>
                          <div className={'flex flex-col'}>
                            <input
                              className={`appearance-none ${
                                error ? 'border-red-500' : ''
                              } border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline`}
                              style={{ color: 'var(--text-grey-700)' }}
                              id="name"
                              type="text"
                              placeholder="Name"
                              onChange={() => {
                                if (textInputRef.current) {
                                  setPlayerName(textInputRef.current.value);
                                }
                                setError(undefined);
                              }}
                              onBlur={() => {
                                window.scrollTo(0, 0);
                              }}
                              value={playerName}
                              ref={textInputRef}
                            />
                          </div>
                        </div>

                        <div
                          className="flex mt-4 cursor-pointer"
                          onClick={() => {
                            setShowAdvancedSettings(!showAdvancedSettings);
                          }}
                        >
                          <h4 className="mr-4 select-none font-bold">
                            Advanced Settings
                          </h4>
                          <i
                            className={`ri-arrow-${
                              showAdvancedSettings ? 'up' : 'down'
                            }-s-line`}
                          ></i>
                        </div>

                        {showAdvancedSettings && (
                          <div
                            className={
                              'flex gap-2 items-center mt-4 transition-all duration-500 ease-in-out border-l-4 border-blue-500 p-4'
                            }
                          >
                            <div
                              className={'flex flex-col'}
                              style={{ width: '200px' }}
                            >
                              <div className={'font-bold'}>
                                Enable Co-Op Mode
                              </div>
                              <div className={'text-sm'}>
                                {allowConnectFromAnotherDevice
                                  ? 'Someone else can join this game from another device with the same name.'
                                  : 'There can only be one of you.'}
                              </div>
                            </div>
                            <div>
                              <Toggle
                                checked={allowConnectFromAnotherDevice}
                                onChange={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setAllowConnectFromAnotherDevice(
                                    !allowConnectFromAnotherDevice
                                  );
                                }}
                              />
                            </div>
                          </div>
                        )}

                        <div className={'mt-4 self-end'}>
                          <AsyncButton
                            label={'Join'}
                            onClick={async () => {
                              try {
                                const name = textInputRef.current?.value;
                                if (!name) {
                                  runInAction(() => {
                                    setError('Please enter a name');
                                  });
                                  return;
                                }
                                await doJoinGame(name);
                              } catch (error: any) {
                                setError(error);
                              }
                            }}
                          />
                        </div>
                        {error && (
                          <div className={'mt-2 text-red-500 text-sm'}>
                            {error}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              );
            },
          })}
        </div>
        <div className="mt-4 flex justify-center items-center mb-16">
          <Ad size="300x250" />
        </div>
      </div>
    </Screen>
  );
});
