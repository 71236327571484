// declare var gtag: Gtag.Gtag;
declare namespace Gtag {
  interface Gtag {
    (
      command: 'config',
      targetId: string,
      config?: ControlParams | EventParams | CustomParams
    ): void;
    (command: 'set', config: CustomParams): void;
    (command: 'js', config: Date): void;
    (
      command: 'event',
      eventName: EventNames | string,
      eventParams?: ControlParams | EventParams | CustomParams
    ): void;
  }

  interface CustomParams {
    [key: string]: any;
  }

  interface ControlParams {
    groups?: string | string[];
    send_to?: string | string[];
    event_callback?: () => void;
    event_timeout?: number;
  }

  type EventNames =
    | 'add_payment_info'
    | 'add_to_cart'
    | 'add_to_wishlist'
    | 'begin_checkout'
    | 'checkout_progress'
    | 'exception'
    | 'generate_lead'
    | 'login'
    | 'page_view'
    | 'purchase'
    | 'refund'
    | 'remove_from_cart'
    | 'screen_view'
    | 'search'
    | 'select_content'
    | 'set_checkout_option'
    | 'share'
    | 'sign_up'
    | 'timing_complete'
    | 'view_item'
    | 'view_item_list'
    | 'view_promotion'
    | 'view_search_results';

  interface EventParams {
    checkout_option?: string;
    checkout_step?: number;
    content_id?: string;
    content_type?: string;
    coupon?: string;
    currency?: string;
    description?: string;
    fatal?: boolean;
    items?: Item[];
    method?: string;
    number?: string;
    promotions?: Promotion[];
    screen_name?: string;
    search_term?: string;
    shipping?: Currency;
    tax?: Currency;
    transaction_id?: string;
    value?: number;
    event_label?: string;
    event_category?: string;
  }

  type Currency = string | number;

  interface Item {
    brand?: string;
    category?: string;
    creative_name?: string;
    creative_slot?: string;
    id?: string;
    location_id?: string;
    name?: string;
    price?: Currency;
    quantity?: number;
  }

  interface Promotion {
    creative_name?: string;
    creative_slot?: string;
    id?: string;
    name?: string;
  }
}

declare global {
  interface Window {
    gtag?: Gtag.Gtag;
  }
}

export type GACustomEvents =
  | 'cell_correct'
  | 'cell_incorrect'
  | 'game_completed'
  | 'game_start'
  | 'game_created'
  | 'join_next_game'
  | 'message_sent'
  | 'joined_from_lobby'
  | 'game_link_copied'
  | 'game_link_copied__user_menu'
  | 'colors_enabled'
  | 'colors_disabled'
  | 'notifications_enabled'
  | 'notifications_disabled'
  | 'discord_joined'
  | 'undo'
  | 'game_reset'
  | 'shared_game_success'
  | 'shared_game_failure'
  | 'shared_usdoku_success'
  | 'shared_usdoku_failure'
  | 'hardcore_number_entered'
  | 'dark_mode_enabled'
  | 'dark_mode_disabled';

export const GAEvent = (
  eventName: Gtag.EventNames | GACustomEvents,
  eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams
): void => {
  if (window.gtag && typeof window.gtag == 'function') {
    window.gtag('event', eventName, eventParams);
  }
};
