import React, { FC } from 'react';
import CKPhoto from '../../assets/photos/ck.jpg';
import HNPhoto from '../../assets/photos/hn.jpg';
import WWPhoto from '../../assets/photos/ww.jpg';

export interface TeamMemberBlockProps {
  photo: string;
  name: string;
  description: string;
}

const MAP: Record<string, string> = {
  ck: CKPhoto,
  hn: HNPhoto,
  ww: WWPhoto,
};

export const TeamMemberBlock: FC<TeamMemberBlockProps> = (props) => {
  return (
    <div
      className={'p-8 rounded-sm flex gap-4'}
      style={{
        width: 'fit-content',
        minWidth: '350px',
        background: 'var(--background-grey-300)',
      }}
    >
      <img
        style={{
          width: '80px',
          height: '80px',
          borderRadius: '50%',
        }}
        src={MAP[props.photo]}
      />
      <div className={'flex flex-col justify-center'}>
        <div className={'font-semibold'}>{props.name}</div>
        <div className={'text-sm'}>{props.description}</div>
      </div>
    </div>
  );
};
