import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { SudokuGrid } from '../components/SudokuGrid';
import { InputModeSelector } from '../components/InputModeSelector';
import { InputGrid } from '../components/InputGrid';
import { Store } from '../Stores/Store';
import { Screen } from '../Screens/Screen';
import { EndScreenTable } from '../components/EndScreenTable';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import { ToastContainer, toast } from 'react-toastify';
import { GAEvent } from '../lib/GoogleAnalytics';

import {
  ChatPayload,
  DynamicNotificationsPayload,
  Events,
  GameSupersededPayload,
  NotificationPayload,
  PlayerFinishedPayload,
} from '../Stores/Events';
import { AsyncButton } from './AsyncButton';
import { API } from '../lib/API';
import { ChatToast } from '../lib/Toasts';
import { SmartNotificationBar } from '../components/SmartNotificationBar';
import { take } from 'ramda';
import { UserMenu } from '../components/UserMenu';
import { Ad } from '../components/Ad';

export interface GameWidgetProps {
  store: Store;
  onBack: () => void;
}

export const Game = styled.div((props: any) => {
  return {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };
});

function getGameSizeBasedOnWindow() {
  // do fancy checks here so that the game always fits
  let width = document.body.clientWidth; // window.innerWidth;
  let height = document.body.clientHeight; //window.innerHeight;
  let size = width * 0.6;

  // landscape
  if (width > height) {
    size = height * 0.5;
  } else if (height / width > 1.4) {
    size = width * 0.95;
  }

  return Math.floor(size);
}

export const GameWidget: React.FC<any> = observer((props: GameWidgetProps) => {
  const [size, setSize] = useState(getGameSizeBasedOnWindow());
  const [showConfetti, setShowConfetti] = useState(false);
  const { height, width } = useWindowSize();

  const [supersedingGame, setSupersedingGame] = useState<string | undefined>(
    undefined
  );

  const handleWindowResize = (event: any) => {
    setSize(getGameSizeBasedOnWindow());
  };

  // Register event listeners
  useEffect(() => {
    props.store.events.on(
      Events.DynamicNotifications,
      (payload: DynamicNotificationsPayload) => {
        for (let message of payload) {
          props.store.dynamicNotificationStore.addToQueue(message);
        }
      }
    );
    props.store.events.on(
      Events.PlayerFinished,
      (payload: PlayerFinishedPayload) => {
        const me = payload.name === props.store.playerName;
        if (me) {
          setShowConfetti(true);
          GAEvent('game_completed');
          return;
        }

        toast(
          <>
            <strong>{payload.name}</strong> has finished.
          </>
        );
      }
    );
    props.store.events.on(
      Events.Notification,
      (payload: NotificationPayload) => {
        const { message } = payload;
        toast(message);
      }
    );
    props.store.events.on(
      Events.GameSuperseded,
      (payload: GameSupersededPayload) => {
        toast(
          <div className={'flex flex-col'}>
            <span>
              A new game has started: <strong>{payload.gameCode}</strong>
            </span>
            <span className={'text-sm'}>
              You can find the button to join below.
            </span>
          </div>
        );
        setSupersedingGame(payload.gameCode);
      }
    );
    props.store.events.on(Events.Chat, (payload: ChatPayload) => {
      const color = props.store.players.find((p) => p.name === payload.name)
        ?.color;
      toast(
        <ChatToast
          color={color || 'white'}
          message={payload.message}
          name={payload.name}
        />
      );
    });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  return (
    <Screen title={'Play'} isGameScreen={true}>
      <>
        {showConfetti && (
          <Confetti
            gravity={0.25}
            numberOfPieces={300}
            width={width}
            height={height}
            recycle={false}
            onConfettiComplete={() => {
              setShowConfetti(false);
            }}
          />
        )}
        <ToastContainer
          autoClose={2500}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          style={{ paddingTop: 'env(safe-area-inset-top, 0px)' }}
          limit={1}
        />
        <div
          className={'pl-12 pt-4 pb-4 pr-12 flex justify-between items-center'}
        >
          <div
            className="flex items-center text-indigo-400 no-underline hover:no-underline cursor-pointer font-bold text-2xl "
            onClick={props.onBack}
          >
            UsDoku
          </div>
          <div className={'flex items-center justify-center'}>
            <div style={{ width: '8px' }} />
            <UserMenu store={props.store} />
          </div>
        </div>

        <Game>
          <div className="mb-4 w-full">
            <SmartNotificationBar
              message={
                (props.store.settingsStore.dynamicNotificationsEnabled &&
                  props.store.dynamicNotificationStore.current?.payload) ||
                podiumScoresMessage(props.store)
              }
            />
          </div>
          <SudokuGrid
            boardState={props.store.boardStore.boardState}
            dark={props.store.settingsStore.darkMode}
            mode={props.store.mode}
            pencilMarksState={
              props.store.hideCompleted == true
                ? {}
                : props.store.boardStore.pencilMarksState
            }
            background="var(--sudoku-grid-background)"
            size={size}
            onBlockSelect={(block: string) => {
              props.store.boardStore.selectedBlock = block;
            }}
            selectedBlock={props.store.boardStore.selectedBlock}
            currentPlayerName={props.store.playerName}
            userSetting_swicthOffAllColors={
              props.store.settingsStore.colorsDisabled
            }
          />
          <>
            <InputModeSelector
              background="var(--input-mode-selector-background)"
              size={size}
              mode={props.store.mode}
              inputMode={props.store.boardStore.inputMode}
              inputModeChanged={(inputMode) => {
                props.store.boardStore.inputMode = inputMode;
              }}
              playerScore={props.store.currentPlayerScore}
              onErase={() => {
                props.store.boardStore.handleNumberSelected({
                  value: 0,
                  isUndo: false,
                });
              }}
              canUndo={props.store.boardStore.canUndo}
              onUndo={() => {
                props.store.boardStore.handleUndo();
              }}
              numberOfPlayers={props.store.players.length}
              playerColor={props.store.currentPlayer?.color ?? 'transparent'}
              validChatOptions={props.store.validChatOptions}
              onMessageSelected={(message) => {
                return API.Chat(
                  props.store.gameCode,
                  props.store.playerName,
                  message
                );
              }}
            />
            {props.store.completedPlayers.indexOf(props.store.playerName) <
              0 && (
              <InputGrid
                size={size / 8}
                onNumberPressed={(value: number) => {
                  props.store.boardStore.handleNumberSelected({
                    value: value,
                    isUndo: false,
                  });
                }}
                inputMode={props.store.boardStore.inputMode}
                onArrowPressed={(arrow: string) => {
                  props.store.boardStore.handleArrowPressed(arrow);
                }}
                completedNumbers={props.store.boardStore.completedNumbers}
              />
            )}
          </>
          {supersedingGame && (
            <div
              className="flex flex-col justify-center items-center text-center mt-4 mb-4 p-8 w-full"
              style={{ background: 'var(--background-grey-200' }}
            >
              <div className="font-bold">Keep the party going!</div>
              <div className={'w-56 lg-max:w-48 text-sm mt-4'}>
                <AsyncButton
                  pull={'center'}
                  label={`Join Next Game`}
                  onClick={async () => {
                    // NB: this resets the store.
                    GAEvent('join_next_game');
                    window.location.href = `/${supersedingGame}`;
                  }}
                />
              </div>
            </div>
          )}
          {props.store.completedPlayers.indexOf(props.store.playerName) >=
            0 && (
            <div className={'flex flex-col items-center'}>
              <button
                className={`bg-orange-500 text-whitehover:bg-orange-500 lg-max:mb-4 font-semibold hover:text-white py-2 px-4 border border-orange-500 hover:border-transparent rounded mr-4 mt-4`}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  props.store.hideCompleted = !props.store.hideCompleted;
                }}
              >
                {props.store.hideCompleted == true
                  ? 'Show Completed'
                  : 'Hide Completed'}
              </button>
            </div>
          )}
          <EndScreenTable
            players={props.store.players}
            startedAt={props.store.startedAt}
            mode={props.store.mode}
            boardEntries={props.store.boardStore.boardEntries}
          />
          <div className="mt-4 flex justify-center items-center">
            {window.screen.width < 800 ? (
              <Ad size="300x250" />
            ) : (
              <Ad size="728x90" />
            )}
          </div>
          <div
            className="flex flex-col justify-center items-center text-center mt-4 mb-4 p-8 w-full"
            style={{ background: 'var(--background-grey-200' }}
          >
            <div className="font-bold">Game info</div>
            <div>Difficulty: {props.store.difficulty}</div>
            <div>Game mode: {props.store.mode}</div>
          </div>
        </Game>
      </>
    </Screen>
  );
});

function podiumScoresMessage(store: Store) {
  return {
    type: 'scores' as 'scores',
    relativePriority: 0,
    scores:
      store.mode === 'original'
        ? take(
            3,
            store.playerScores.map(({ name, color, score }) => {
              return {
                name,
                color,
                score: `${score} pts`,
              };
            })
          )
        : take(
            3,
            store.playerGuessPercentages.map(({ color, name, percentage }) => {
              return {
                name,
                color,
                score: `${percentage}%`,
              };
            })
          ),
  };
}
