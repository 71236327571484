import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { Screen } from '../Screen';
import RunningSVG from '../../assets/running.svg';

export interface StartingScreenProps {
  goHome: () => void;
  timeToGameStart: number;
}

export const StartingScreen: React.FC<StartingScreenProps> = observer(
  (props: StartingScreenProps) => {
    return (
      <Screen title={'New Game'}>
        <div className="flex flex-col items-center sm-max:px-6">
          <div
            className={'pl-24 md-max:pl-12 sm-max:pl-6 w-full h-full'}
            style={{ background: 'var(--background-grey-100)' }}
          >
            <div
              className="flex items-center text-indigo-400 no-underline hover:no-underline cursor-pointer font-bold text-2xl "
              onClick={props.goHome}
            >
              UsDoku
            </div>
          </div>
          <div className={'flex-col flex items-center'}>
            <div className="flex items-center justify-center rounded-sm  px-20 py-12">
              <div className="h-24 w-24">
                <RunningSVG />
              </div>
              <div className="flex flex-col justify-center items-left  ml-4">
                <div className="font-bold mb-1">
                  Your UsDoku game is about to start
                </div>
                <div className={'w-56 lg-max:w-48 text-xl'}>
                  {props.timeToGameStart > 0
                    ? `T minus ${props.timeToGameStart}`
                    : 'Liftoff!'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Screen>
    );
  }
);
