import React from 'react';

export interface WhatsNewItem {
  iconName: string;
  title: string;
  description: JSX.Element;
}

export const WhatsNew: WhatsNewItem[] = [
  {
    iconName: 'ri-discord-fill',
    title: 'Discord Community Server',
    description: (
      <>
        Ready to take your gaming experience to the next level? Join our vibrant
        Discord community{' '}
        <a
          href="https://discord.gg/hbY5pBYSkh"
          target="_blank"
          className="text-blue-500"
        >
          <strong>here</strong>
        </a>{' '}
        and become a part of an exciting world where UsDoku players unite, share
        tips, and forge lasting friendships!
      </>
    ),
  },
  {
    iconName: 'ri-rainbow-line',
    title: 'Colors: on or off?',
    description: (
      <>
        Not a fan of having your UsDoku Original grid rainbowed with colors? No
        problem. You can now turn them off in the new settings menu found on
        your game screen.
      </>
    ),
  },
  {
    iconName: 'ri-speed-line',
    title: 'Hardcore mode',
    description: (
      <>
        A new game mode is now available. In Hardcore mode, there is no scoring
        and mistakes aren't pointed out. The first to complete the puzzle wins.
      </>
    ),
  },
  {
    iconName: 'ri-user-add-line',
    title: 'Make The Circle Bigger!',
    description: (
      <>
        UsDoku games can now support up to <strong>ten players</strong> at a
        time. Call in the extended squad and battle it out to find the ultimate
        UsDoku champion.
      </>
    ),
  },
  {
    iconName: 'ri-home-smile-line',
    title: 'Lobbies',
    description: (
      <>
        Do you love UsDoku but your friends aren't always around to play?{' '}
        <strong>Fear no more </strong> - we've introduced lobbies & public
        games. When creating your game, you can mark your game as public. Other
        players from around the world can now discover your game and join in on
        the fun.
      </>
    ),
  },
  {
    iconName: 'ri-chat-3-line',
    title: 'Chat',
    description: (
      <>
        Now you can communicate with your party without leaving your UsDoku
        game. Tell your party that you're <strong>ready</strong>,{' '}
        <strong>excited about this puzzle</strong> or{' '}
        <strong>yes, you'd like to play another game</strong>.
      </>
    ),
  },
  {
    iconName: 'ri-send-plane-2-line',
    title: 'Party On!',
    description: (
      <>
        As soon as your party's first player finishes, a new game will
        automatically be created for you. No more ending the game, creating a
        new one, and having to find a way to communicate the new code. Just
        click <strong>Join Next Game</strong> and keep on solving!
      </>
    ),
  },
];
