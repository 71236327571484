import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { Player, Store } from '../Stores/Store';
import { BoardEntry } from '../Stores/Board';
import { BoardEntryState, MiniSudokuIndicator } from './MiniSudokuIndicator';
import { Ad } from './Ad';

export interface EndScreenTableProps {
  players: Player[];
  startedAt: number;
  mode: string;
  boardEntries: BoardEntry[];
}

export const EndScreenTableContainer = styled.div((props: any) => {
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  };
});

interface FinalPlayer extends Player {
  calculatedScore: number;
  numberOfFirsts?: number;
  numberOfMistakes?: number;
  completedPercentage: number;
  numberOfEntries: number;
  completedTime?: number;
  lateJoinCompletedTime?: number;
  boardStates: BoardEntryState[];
}

function ordinal_suffix_of(i: number) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return 'st';
  }
  if (j == 2 && k != 12) {
    return 'nd';
  }
  if (j == 3 && k != 13) {
    return 'rd';
  }
  return 'th';
}

function getPlayerCompletedStatus(player: FinalPlayer) {
  if (player.completedPercentage == 100) {
    if (player.completedAt) {
      return (
        <div
          className="text-sm text-green-500 flex flex-row"
          style={{ color: 'var(--text-grey-600)' }}
        >
          <i className="ri-checkbox-circle-line mr-1"></i> Correct
        </div>
      );
    }
    return (
      <div
        className="text-sm text-red-500 flex flex-row"
        style={{ color: 'var(--text-grey-600)' }}
      >
        <i className="ri-close-circle-line mr-1"></i> Incorrect
      </div>
    );
  }
}

const getPlayerScores = (
  players: Player[],
  startedAt: number,
  mode: string,
  boardEntries: BoardEntry[]
) => {
  if (!players || players.length == 0) {
    return null;
  }

  const openEntries = boardEntries.filter((b) => b.type == 'open');
  const allGuesses = openEntries
    .map((entry) => (entry as any).guesses)
    .filter((g) => g.length > 0);
  const flattenedGuesses: any[] = [].concat.apply([], allGuesses);

  let tempPlayers: FinalPlayer[] = players.map((player: Player) => {
    let completedTime;
    let lateJoinCompletedTime;
    if (player.completedAt) {
      completedTime = player.completedAt - startedAt;

      if (player.joinedAt) {
        lateJoinCompletedTime = player.completedAt - player.joinedAt;
      }
    }
    const numberOfEntries = flattenedGuesses.filter(
      (entry) => entry.name == player.name
    ).length;
    const completedPercentage = Math.floor(
      (numberOfEntries / openEntries.length) * 100
    );

    const boardStates: BoardEntryState[] = boardEntries.map(
      (boardEntry: BoardEntry) => {
        if (boardEntry.type == 'closed') {
          return 'closed';
        } else if (boardEntry.type == 'guess') {
          return 'entered';
        } else {
          if (mode == 'original') {
            return boardEntry.scorers.some((scorer) => scorer == player.name)
              ? 'entered'
              : 'empty';
          } else {
            const guess = boardEntry.guesses.find((g) => g.name == player.name);
            if (guess && guess.value) {
              return 'entered';
            }
            return 'empty';
          }
        }
      }
    );

    return {
      ...player,
      calculatedScore: Store.getPlayerScore(player),
      numberOfFirsts: player.score.filter((score) => score.value == 10).length,
      numberOfEntries: numberOfEntries,
      completedPercentage: completedPercentage,
      boardStates,
      numberOfMistakes: player.score.filter((score) => score.value < 0).length,
      completedTime,
      lateJoinCompletedTime,
    };
  });
  if (mode == 'original') {
    tempPlayers.sort((a, b) => {
      return b.calculatedScore - a.calculatedScore;
    });
  } else {
    tempPlayers.sort((a, b) => {
      if (a.completedTime && b.completedTime) {
        return a.completedTime - b.completedTime;
      } else if (a.completedTime && !b.completedTime) {
        return -1;
      } else if (!a.completedTime && b.completedTime) {
        return 1;
      }

      return b.numberOfEntries - a.numberOfEntries;
    });
  }

  return (
    <div className="container flex mx-auto w-full items-center justify-center">
      <ul className="flex flex-col  p-4">
        <li className="border-gray-400 flex flex-row mb-2 w-full justify-center">
          <Ad size="300x50" />
        </li>
        {tempPlayers.map((player: FinalPlayer, index: number) => {
          return (
            <li className="border-gray-400 flex flex-row mb-2">
              <div
                className="select-none rounded-md flex flex-1 items-center p-2"
                style={{ background: 'var(--background-grey-200)' }}
              >
                <div className="flex flex-col rounded-md justify-center items-center ml-2 mr-4">
                  <MiniSudokuIndicator
                    playerColor={player.color}
                    boardStates={player.boardStates}
                  />
                </div>
                <div className="flex-1 pl-1 mr-16">
                  <div className="font-medium flex flex-row mt-4 mb-1">
                    <div
                      className={'mr-2'}
                      style={{
                        backgroundColor: player.color,
                        height: '24px',
                        width: '24px',
                        borderRadius: '2px',
                      }}
                    />
                    {player.name}
                  </div>
                  <div
                    className="text-sm"
                    style={{ color: 'var(--text-grey-600)' }}
                  >
                    {index + 1}
                    <sup>{ordinal_suffix_of(index + 1)}</sup> of{' '}
                    {tempPlayers.length}
                  </div>
                  {player.joinedAt && (
                    <div
                      className="text-sm"
                      style={{ color: 'var(--text-grey-500)' }}
                    >
                      Joined mid-game
                    </div>
                  )}

                  {mode == 'original' && (
                    <>
                      <div
                        className="text-sm"
                        style={{ color: 'var(--text-grey-600)' }}
                      >
                        Score: {player.calculatedScore}
                      </div>
                      <div
                        className="text-sm"
                        style={{ color: 'var(--text-grey-600)' }}
                      >
                        Firsts: {player.numberOfFirsts}
                      </div>
                      <div
                        className="text-sm"
                        style={{ color: 'var(--text-grey-600)' }}
                      >
                        Mistakes: {player.numberOfMistakes}
                      </div>
                    </>
                  )}

                  {mode == 'hardcore' && (
                    <>
                      <div
                        className="text-sm"
                        style={{ color: 'var(--text-grey-600)' }}
                      >
                        Completed:{' '}
                        <span style={{ minWidth: '3em', width: '3em' }}>
                          {player.completedPercentage}%
                        </span>
                      </div>
                      {getPlayerCompletedStatus(player)}
                    </>
                  )}
                </div>

                <div className="flex flex-col">
                  <div
                    className="text-xs"
                    style={{ color: 'var(--text-grey-600)' }}
                  >
                    {player.completedTime
                      ? new Date(player.completedTime)
                          .toISOString()
                          .substr(11, 8)
                      : ''}
                  </div>
                  <div
                    className="text-xs"
                    style={{ color: 'var(--text-grey-500)' }}
                  >
                    {player.lateJoinCompletedTime
                      ? new Date(player.lateJoinCompletedTime)
                          .toISOString()
                          .substr(11, 8)
                      : ''}
                  </div>
                </div>
              </div>
            </li>
          );
        })}
        <li className="border-gray-400 flex flex-row mb-2  w-full justify-center">
          <Ad size="300x50_2" />
        </li>
      </ul>
    </div>
  );
};

export const EndScreenTable: React.FC<EndScreenTableProps> = observer(
  (props) => {
    return (
      <EndScreenTableContainer>
        {getPlayerScores(
          props.players,
          props.startedAt,
          props.mode,
          props.boardEntries
        )}
      </EndScreenTableContainer>
    );
  }
);
