import { v4 } from 'uuid';

export const LOCALSTORAGE_DEVICE_ID_KEY = 'usdoku:pen';

function pad(d: number): string {
  return `${d <= 9 ? '0' : ''}${d}`;
}

export const deviceId = () => {
  const current = localStorage.getItem(LOCALSTORAGE_DEVICE_ID_KEY);
  if (current == null) {
    const date = new Date();
    const shortYear = date.getFullYear().toString().substring(2);
    const dateInfo = `${pad(date.getMonth() + 1)}${shortYear}`;

    const key = `${v4()}-${dateInfo}`;
    localStorage.setItem(LOCALSTORAGE_DEVICE_ID_KEY, key);
    return key;
  }

  return current;
};
