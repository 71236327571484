export const interlace = <T, U>(list: T[], spacer: U) => {
  return list.reduce((memo, x, i) => {
    const updated = [...memo];
    updated.push(x);
    if (i !== list.length - 1) {
      updated.push(spacer);
    }
    return updated;
  }, [] as (T | U)[]);
};

export const shuffle = <T>(list: T[]): T[] => {
  return list.sort((a, b) => Math.random() - 0.5);
};

export function timeDifference(current: number, previous: number) {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + ' seconds ago';
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + ' minutes ago';
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + ' hours ago';
  } else if (elapsed < msPerMonth) {
    return 'approximately ' + Math.round(elapsed / msPerDay) + ' days ago';
  } else if (elapsed < msPerYear) {
    return 'approximately ' + Math.round(elapsed / msPerMonth) + ' months ago';
  } else {
    return 'approximately ' + Math.round(elapsed / msPerYear) + ' years ago';
  }
}

export const bisect = <T>(
  array: T[],
  condition: (item: T) => boolean
): [T[], T[]] => {
  const satisfied: T[] = [];
  const rejected: T[] = [];

  for (let i = 0; i < array.length; i++) {
    const candidate = array[i];
    if (condition(candidate)) {
      satisfied.push(candidate);
    } else {
      rejected.push(candidate);
    }
  }

  return [satisfied, rejected];
};
