import { repeat } from 'ramda';
import { Screen } from '../Screen';
import React, { FC, useRef, useState } from 'react';
import HomeSVG from '../../assets/home.svg';
import CreateSVG from '../../assets/create.svg';
import JoinSVG from '../../assets/join.svg';
import StartSVG from '../../assets/start.svg';
import ScoreSVG from '../../assets/score.svg';
import { PlayerIndicator } from '../../components/PlayerIndicator';
import { getRandomLandingCombo } from '../../lib/Phrases';
import { observer } from 'mobx-react';
import { WhatsNew } from '../../lib/WhatsNew';
import { WhatsNewBlock } from './WhatsNewBlock';
import { shuffle } from '../../lib/util';
import { TeamMemberBlock } from './TeamMemberBlock';
import CoffeeButton from '../../widgets/CoffeeButton';
import Toggle from 'react-toggle';
import { SettingsStore } from '../../Stores/SettingsStore';
const { version } = require('../../../package.json');

export interface LandingProps {
  onCreate: () => void;
  onFind: () => void;
  onJoin: (code: string) => void;
  mostRecentGameCode?: string;
  settingsStore: SettingsStore;
}

const combo = getRandomLandingCombo();
const PHOTO_ORDER = shuffle([
  {
    photo: 'ck',
    name: 'Christiaan',
    description: 'A full-stack engineer',
  },
  {
    photo: 'ww',
    name: 'Wessel',
    description: 'A full-stack engineer',
  },
  {
    photo: 'hn',
    name: 'Hannes',
    description: 'A product manager',
  },
]);

export const Landing: FC<LandingProps> = observer((props) => {
  const { mostRecentGameCode } = props;
  const [modalTopOpen, setModalTopOpen] = useState(false);
  const [modalBottomOpen, setModalBottomOpen] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const textInputRef = useRef<HTMLInputElement | null>(null);

  const onJoin = () => {
    let value = textInputRef.current?.value;
    if (value) {
      value = value.trim().toUpperCase();
    }
    if (!value || !value.match(/^[A-Z]{4}$/)) {
      return setError('Please enter a 4 letter code');
    }
    props.onJoin(value);
  };
  const textOnChange = () => {
    setError(undefined);
  };

  const buildModal = () => {
    return (
      <div
        className="flex absolute flex-col items-center justify-center pl-12 pr-12 pb-12 pt-4 border-gray-700 border-solid"
        style={{
          borderWidth: '1px',
          background: 'var(--background-blank)',
        }}
      >
        <div
          className={'text-sm w-full text-right cursor-pointer hover:underline'}
          onClick={() => {
            setModalTopOpen(false);
            setModalBottomOpen(false);
            setError(undefined);
          }}
        >
          Close
        </div>
        <div className="font-bold mb-1">Join a game</div>
        <div className={'w-56 text-sm mb-1'}>
          Already have a code? Let's get going!
        </div>
        <div className={'flex flex-col'}>
          <div className={'flex mt-4'}>
            <div className="flex flex-col">
              <input
                className={`appearance-none border ${
                  error ? 'border-red-500' : ''
                } rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline`}
                style={{ color: 'var(--text-grey-700)' }}
                type="text"
                placeholder="Your code"
                onChange={textOnChange}
                ref={textInputRef}
                onBlur={() => {
                  window.scrollTo(0, 0);
                }}
              />
              {error && (
                <p className="text-red-500 text-xs italic mt-1">{error}</p>
              )}
            </div>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded h-10 ml-4"
              onClick={onJoin}
            >
              Join
            </button>
          </div>
          {mostRecentGameCode && (
            <a
              className={
                'text-blue-400 cursor-pointer text-sm text-center mt-4'
              }
              onClick={() => {
                props.onJoin(mostRecentGameCode);
              }}
            >
              Rejoin {mostRecentGameCode}
            </a>
          )}
        </div>
      </div>
    );
  };

  return (
    <Screen title={'Home'}>
      <>
        <div
          className={
            'pl-24 md-max:pl-12 sm-max:pl-6 w-full h-full opacity-20 flex justify-between'
          }
          style={{ background: 'var(--background-grey-100)' }}
        >
          <a
            className="flex items-center text-indigo-400 no-underline hover:no-underline font-bold text-2xl "
            href="#"
          >
            UsDoku
          </a>
          <div className="flex flex-col mr-4 items-center">
            <Toggle
              defaultChecked={props.settingsStore.darkMode}
              title="Dark Mode"
              icons={false}
              onChange={(e) => {
                props.settingsStore.darkMode = !props.settingsStore.darkMode;
                // document.body.classList.toggle('dark');
              }}
            ></Toggle>
            <span
              className="text-xs mt-1"
              style={{ color: 'var(--dark-mode-toggle-label-text)' }}
            >
              Dark mode
            </span>
          </div>
        </div>
        <div className="h-full pb-24">
          <div className="container pt-8 px-6 mx-auto md-max:flex-col md-max:items-center flex justify-center">
            <div
              className={'w-64 mr-12 md-max:mr-0 md-max:mb-4'}
              style={{
                alignSelf: 'center',
              }}
            >
              <HomeSVG />
            </div>
            <div className="flex flex-col xl:w-2/5 justify-center items-center overflow-y-hidden">
              <div
                className="my-4 font-bold leading-tight text-center text-5xl"
                style={{ color: 'var(--text-purple-800)' }}
              >
                {combo.heading}
              </div>
              <p className="leading-normal text-lg mb-8 text-center slide-in-bottom-subtitle max-w-xl">
                {combo.subtext}
              </p>
              <div className={'flex flex-col items-center'}>
                <div className={'flex justify-center mb-4'}>
                  <button
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-4 cursor-pointer"
                    style={{
                      width: 'fit-content',
                    }}
                    onClick={props.onFind}
                  >
                    Find Game
                  </button>
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4  rounded  cursor-pointer"
                    style={{
                      width: 'fit-content',
                    }}
                    onClick={props.onCreate}
                  >
                    Create Game
                  </button>
                </div>
                <a
                  className={'text-blue-400 cursor-pointer text-sm'}
                  onClick={() => {
                    setModalTopOpen(true);
                  }}
                >
                  Already have a game code? Click here.
                </a>
                {modalTopOpen && buildModal()}
              </div>
            </div>
          </div>
        </div>
        <div className={'container pt-8 px-6 mx-auto flex flex-col'}>
          <div
            className={
              'font-bold leading-tight text-center md:text-left text-2xl'
            }
            style={{ color: 'var(--text-purple-800)' }}
          >
            How it works
          </div>
          <div>
            <section className="max-w-5xl mx-auto py-10">
              <div>
                <div className="flex flex-row">
                  <div className="hidden md:flex flex-col items-center">
                    <div className="w-32 py-5 border border-gray-300 rounded mr-4 uppercase flex flex-col items-center justify-center">
                      <div
                        className="text-3xl font-black"
                        style={{ color: 'var(--text-grey-500)' }}
                      >
                        Step 1
                      </div>
                      <div
                        className="text-sm"
                        style={{ color: 'var(--text-grey-500)' }}
                      >
                        Create
                      </div>
                    </div>
                    <div className="h-full border-l-4 border-transparent">
                      <div className="border-l-4 mr-4 h-full border-gray-300 border-dashed"></div>
                    </div>
                  </div>
                  <div className="flex-auto border rounded  border-gray-300">
                    <div className="flex md:flex-row flex-col items-center">
                      <div className="flex-auto">
                        <div
                          className="md:hidden text-sm font-normal uppercase pt-3 pl-3"
                          style={{ color: 'var(--text-grey-500)' }}
                        >
                          <span className="font-black">Step 1</span> - Create
                        </div>
                        <div
                          className="p-3 text-3xl font"
                          style={{ color: 'var(--text-grey-800)' }}
                        >
                          Create a new game
                        </div>
                        <div className="px-3 pb-6">
                          So you want to play a live multiplayer game Sudoku
                          against your friends? Great! Head over to{' '}
                          <strong>Create a New Game</strong>, select your
                          desired difficulty and go!
                        </div>
                      </div>
                      <div className="md:w-96 w-full p-5">
                        <div
                          className={'w-64 mr-12 md-max:mr-0 md-max:mb-4'}
                          style={{
                            alignSelf: 'center',
                          }}
                        >
                          <CreateSVG />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-start flex-row">
                  <div className="border-t-4 border-r-4 border-transparent">
                    <div className="w-16 ml-16 h-16 border-l-4 border-gray-300 border-dashed border-b-4 rounded-bl-full"></div>
                  </div>
                  <div className="border-t-4 border-transparent flex-auto">
                    <div className="h-16 border-b-4 border-gray-300 border-dashed"></div>
                  </div>
                  <div className="w-16 mt-16 mr-16 h-16 border-r-4 border-gray-300 border-dashed border-t-4 rounded-tr-full"></div>
                </div>
                <div className="flex flex-row-reverse">
                  <div className="hidden md:flex flex-col items-center">
                    <div className="w-32 py-5 border border-gray-300 rounded ml-4 uppercase flex flex-col items-center justify-center">
                      <div
                        className="text-3xl font-black"
                        style={{ color: 'var(--text-grey-500)' }}
                      >
                        Step 2
                      </div>
                      <div
                        className="text-sm"
                        style={{ color: 'var(--text-grey-500)' }}
                      >
                        Invite
                      </div>
                    </div>
                    <div className="h-full border-r-4 border-transparent">
                      <div className="border-l-4 ml-4 h-full border-gray-300 border-dashed"></div>
                    </div>
                  </div>
                  <div className="flex-auto border rounded  border-gray-300">
                    <div className="flex md:flex-row flex-col items-center">
                      <div className="flex-auto">
                        <div
                          className="md:hidden text-sm font-normal uppercase pt-3 pl-3"
                          style={{ color: 'var(--text-grey-500)' }}
                        >
                          <span className="font-black">Step 2</span> - Invite
                        </div>
                        <div
                          className="p-3 text-3xl font"
                          style={{ color: 'var(--text-grey-800)' }}
                        >
                          Invite your friends
                        </div>
                        <div className="px-3 pb-6">
                          You'll see your game's unique 4-letter code, e.g.{' '}
                          <strong>PLAY</strong>. Send this code to your friends,
                          or forward them the Join link you'll see. You, and
                          each of your friends, can provide a user name and
                          you'll receive a random color, e.g.{' '}
                          <div
                            style={{
                              display: 'inline-flex',
                              verticalAlign: 'middle',
                            }}
                          >
                            <PlayerIndicator
                              color={'#000000'}
                              playerName={'Batman'}
                            />
                          </div>
                          , which will be used to denote your blocks.
                        </div>
                      </div>
                      <div className="md:w-96 w-full p-5">
                        <div
                          className={'w-64 mr-12 md-max:mr-0 md-max:mb-4'}
                          style={{
                            alignSelf: 'center',
                          }}
                        >
                          <JoinSVG />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-start flex-row-reverse">
                  <div className="border-t-4 border-l-4 border-transparent">
                    <div className="w-16 mr-16 h-16 border-r-4 border-gray-300 border-dashed border-b-4 rounded-br-full"></div>
                  </div>
                  <div className="border-t-4 border-transparent flex-auto">
                    <div className="h-16 border-b-4 border-gray-300 border-dashed"></div>
                  </div>
                  <div className="w-16 mt-16 ml-16 h-16 border-l-4 border-gray-300 border-dashed border-t-4 rounded-tl-full"></div>
                </div>
                <div className="flex flex-row">
                  <div className="hidden md:flex flex-col items-center">
                    <div className="w-32 py-5 border border-gray-300 rounded mr-4 uppercase flex flex-col items-center justify-center">
                      <div
                        className="text-3xl font-black"
                        style={{ color: 'var(--text-grey-500)' }}
                      >
                        Step 3
                      </div>
                      <div
                        className="text-sm"
                        style={{ color: 'var(--text-grey-500)' }}
                      >
                        Get Started
                      </div>
                    </div>
                    <div className="h-full border-l-4 border-transparent">
                      <div className="border-l-4 mr-4 h-full border-gray-300 border-dashed"></div>
                    </div>
                  </div>
                  <div className="flex-auto border rounded  border-gray-300">
                    <div className="flex md:flex-row flex-col items-center">
                      <div className="flex-auto">
                        <div
                          className="md:hidden text-sm font-normal uppercase pt-3 pl-3"
                          style={{ color: 'var(--text-grey-500)' }}
                        >
                          <span className="font-black">Step 3</span> - Get
                          Started
                        </div>
                        <div
                          className="p-3 text-3xl font"
                          style={{ color: 'var(--text-grey-800)' }}
                        >
                          Let's get going!
                        </div>
                        <div className="px-3 pb-6">
                          Your friends are here, your thumbs are stretched, so
                          there's only one thing left to do -{' '}
                          <strong>Hit Start</strong>. You'll be transported to
                          the game screen, which should look and feel familiar.
                        </div>
                      </div>
                      <div className="md:w-96 w-full p-5">
                        <div
                          className={'w-64 mr-12 md-max:mr-0 md-max:mb-4'}
                          style={{
                            alignSelf: 'center',
                          }}
                        >
                          <StartSVG />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-start flex-row">
                  <div className="border-t-4 border-r-4 border-transparent">
                    <div className="w-16 ml-16 h-16 border-l-4 border-gray-300 border-dashed border-b-4 rounded-bl-full"></div>
                  </div>
                  <div className="border-t-4 border-transparent flex-auto">
                    <div className="h-16 border-b-4 border-gray-300 border-dashed"></div>
                  </div>
                  <div className="w-16 mt-16 mr-16 h-16 border-r-4 border-gray-300 border-dashed border-t-4 rounded-tr-full"></div>
                </div>
                <div className="flex flex-row-reverse">
                  <div className="hidden md:flex flex-col items-center">
                    <div className="w-32 py-5 border border-gray-300 rounded ml-4 uppercase flex flex-col items-center justify-center">
                      <div
                        className="text-3xl font-black"
                        style={{ color: 'var(--text-grey-500)' }}
                      >
                        Step 4
                      </div>
                      <div
                        className="text-sm"
                        style={{ color: 'var(--text-grey-500)' }}
                      >
                        Score
                      </div>
                    </div>
                  </div>
                  <div className="flex-auto border rounded  border-gray-300">
                    <div className="flex md:flex-row flex-col items-center">
                      <div className="flex-auto">
                        <div
                          className="md:hidden text-sm font-normal uppercase pt-3 pl-3"
                          style={{ color: 'var(--text-grey-500)' }}
                        >
                          <span className="font-black">Step 4</span> - Score
                        </div>
                        <div
                          className="p-3 text-3xl font"
                          style={{ color: 'var(--text-grey-800)' }}
                        >
                          Go forth and score
                        </div>
                        <div className="px-3 pb-6">
                          Your mission is to{' '}
                          <strong>play Sudoku as normal</strong>, with a twist:
                          The first player to solve a block correctly gets{' '}
                          <strong>10</strong> points, the second player{' '}
                          <strong>6</strong>, the third player{' '}
                          <strong>3</strong> and the rest <strong>1</strong>.
                          Give an incorrect answer and not only do you lose
                          points, but you're out of the running to score that
                          block entirely.
                        </div>
                      </div>
                      <div className="md:w-96 w-full p-5">
                        <div
                          className={'w-64 mr-12 md-max:mr-0 md-max:mb-4'}
                          style={{
                            alignSelf: 'center',
                          }}
                        >
                          <ScoreSVG />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="container pt-8 pb-16 px-6 mx-auto flex-col items-center flex justify-center">
              <div className="flex flex-col xl:w-2/5 justify-center items-center overflow-y-hidden">
                <div
                  className="my-4 font-bold leading-tight text-center md:text-left text-5xl"
                  style={{ color: 'var(--text-purple-800)' }}
                >
                  Are you ready?
                </div>
                <div className={'flex flex-col items-center mt-4'}>
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4 cursor-pointer"
                    style={{
                      width: 'fit-content',
                    }}
                    onClick={props.onCreate}
                  >
                    Create a New Game
                  </button>
                  <a
                    className={
                      'text-blue-400 cursor-pointer text-sm text-center'
                    }
                    onClick={() => {
                      setModalBottomOpen(true);
                    }}
                  >
                    Already have a game code? Click here.
                  </a>
                </div>
                {modalBottomOpen && buildModal()}
              </div>
            </div>
          </div>
        </div>
        <div className={'container pt-8 px-6 mx-auto flex flex-col'}>
          <div
            className={
              ' font-bold leading-tight text-center md:text-left text-2xl'
            }
            style={{ color: 'var(--text-purple-800)' }}
          >
            Meet The Team
          </div>
          <div
            className={
              'flex flex-wrap gap-4 mt-8 justify-center lg:grid lg:grid-cols-3 lg:col-gap-4'
            }
          >
            {PHOTO_ORDER.map((props) => (
              <TeamMemberBlock {...props} />
            ))}
          </div>
          <div
            className={'mt-4 p-8 text-sm md-max:text-center'}
            style={{ background: 'var(--background-grey-300)' }}
          >
            <i className="ri-star-fill align-middle text-blue-500"></i>{' '}
            <span className={'align-middle'}>
              We created UsDoku as a passion project and we're committed to
              keeping it <strong>free</strong>. If you'd like to make a donation
              to help us achieve that, please feel free to{' '}
              <a
                className={'font-bold hover:underline'}
                style={{ color: 'var(--text-purple-800)' }}
                href="https://www.buymeacoffee.com/usdoku"
              >
                <img
                  className={'inline-block px-2 h-8'}
                  src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg"
                />
                buy us a coffee
              </a>
              .
            </span>
          </div>
        </div>
        <div className={'container pt-8 px-6 mx-auto flex flex-col pb-8'}>
          <div
            className={
              'font-bold leading-tight text-center md:text-left text-2xl'
            }
            style={{ color: 'var(--text-purple-800)' }}
          >
            What's New
          </div>
          <div
            className={
              'grid grid-cols-3 gap-4 md-max:grid-cols-1 mt-4 sm-max:py-4'
            }
          >
            {WhatsNew.map((props) => {
              return (
                <div className={'h-full'}>
                  <WhatsNewBlock {...props} />
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="w-full"
          style={{ background: 'var(--background-grey-200' }}
        >
          <div className="w-full pt-16 pb-6 text-sm text-center fade-in">
            <a
              className="no-underline hover:no-underline"
              style={{ color: 'var(--text-grey-500)' }}
              href="#"
            >
              &copy; UsDoku {new Date().getFullYear()}. |{' '}
              <a href="/privacy">Privacy Policy</a>
              <br />
              {version}
            </a>
          </div>
        </div>
      </>
    </Screen>
  );
});

export const Blocks = () => {
  return repeat(
    <div
      className={'block'}
      style={{
        height: '96px',
        width: '96px',
        borderTop: '1px solid lightgray',
        borderLeft: '1px solid lightgray',
      }}
    />,
    9
  );
};
