import { observable, observe, runInAction } from 'mobx';
import { KVStorageEngine, StorageKeys } from '../lib/Engines/KVStorageEngine';

const KV_KEYS = {
  colorsDisabled: 'settings__colors-disabled' as StorageKeys,
  dynamicNotificationsEnabled: 'settings__notifications-enabled-v2' as StorageKeys,
  automaticHidePencils: 'settings__automatic-hide-pencils' as StorageKeys,
  darkMode: 'settings__dark-mode' as StorageKeys,
};

export class SettingsStore {
  @observable colorsDisabled: boolean = false;
  @observable dynamicNotificationsEnabled: boolean = true;
  @observable automaticHidePencils: boolean = true;
  @observable darkMode: boolean = false;

  constructor() {
    observe(this, 'colorsDisabled', (change) => {
      KVStorageEngine.setItem(
        KV_KEYS.colorsDisabled,
        JSON.stringify(change.newValue)
      );
    });
    observe(this, 'dynamicNotificationsEnabled', (change) => {
      KVStorageEngine.setItem(
        KV_KEYS.dynamicNotificationsEnabled,
        JSON.stringify(change.newValue)
      );
    });
    observe(this, 'automaticHidePencils', (change) => {
      KVStorageEngine.setItem(
        KV_KEYS.automaticHidePencils,
        JSON.stringify(change.newValue)
      );
    });
    observe(this, 'darkMode', (change) => {
      KVStorageEngine.setItem(
        KV_KEYS.darkMode,
        JSON.stringify(change.newValue)
      );
    });
  }

  load = async () => {
    await this.loadColorsDisabled();
    await this.loadDynamicNotificationsEnabled();
    await this.loadAutomaticHidePencilsEnabled();
    await this.loadDarkMode();
  };

  private loadColorsDisabled = async () => {
    const candidate = await KVStorageEngine.getItem(KV_KEYS.colorsDisabled);
    if (candidate === null) {
      return;
    }

    try {
      const value = JSON.parse(candidate);
      if (typeof value === 'boolean') {
        runInAction(() => (this.colorsDisabled = value));
      }
    } catch (error) {}
  };

  private loadDynamicNotificationsEnabled = async () => {
    const candidate = await KVStorageEngine.getItem(
      KV_KEYS.dynamicNotificationsEnabled
    );
    if (candidate === null) {
      return;
    }

    try {
      const value = JSON.parse(candidate);
      if (typeof value === 'boolean') {
        runInAction(() => (this.dynamicNotificationsEnabled = value));
      }
    } catch (error) {}
  };

  private loadAutomaticHidePencilsEnabled = async () => {
    const candidate = await KVStorageEngine.getItem(
      KV_KEYS.automaticHidePencils
    );
    if (candidate === null) {
      return;
    }

    try {
      const value = JSON.parse(candidate);
      if (typeof value === 'boolean') {
        runInAction(() => (this.automaticHidePencils = value));
      }
    } catch (error) {}
  };

  private loadDarkMode = async () => {
    const candidate = await KVStorageEngine.getItem(KV_KEYS.darkMode);
    if (candidate === null) {
      return;
    }

    try {
      const value = JSON.parse(candidate);
      if (typeof value === 'boolean') {
        runInAction(() => (this.darkMode = value));
      }
    } catch (error) {}
  };
}
