import { observable, computed, observe, runInAction } from 'mobx';
import { InputMode } from '../components/InputModeSelector';
import { Store, Player } from './Store';
import { GAEvent } from '../lib/GoogleAnalytics';
import { KVStorageEngine } from '../lib/Engines/KVStorageEngine';
import { API } from '../lib/API';

export interface Position {
  column: number;
  row: number;
}

export interface Guess {
  name: string;
  value: number;
}

export type OpenBoardEntryFields = {
  type: 'open';
  scorers: string[];
  guesses: Guess[];
  disqualified: string[];
  backgroundColor: string;
};

type HandleNumberSelectedOptions = {
  value: number;
  selected?: string;
  isUndo?: boolean;
};

export type BoardEntry = Position & { value: number | undefined } & (
    | OpenBoardEntryFields
    | {
        type: 'closed';
      }
    | {
        type: 'guess';
        guess: number;
        backgroundColor: string;
      }
  );

export type PencilMarkState = { [key: string]: number[] };

export interface PreviousState {
  pencils: string;
  selectedBlock: string;
  value?: number;
  type: 'answer' | 'pencil';
}

const MAX_UNDO_DEPTH = 3;

export class BoardStore {
  @observable boardEntries: BoardEntry[];
  @observable private localBoardEntries: BoardEntry[];
  @observable selectedBlock: string;
  @observable inputMode: InputMode;
  @observable pencilMarksState: PencilMarkState;
  @observable previousStates: PreviousState[];

  constructor(private store: Store) {
    this.boardEntries = [];
    this.localBoardEntries = [];
    this.selectedBlock = '';
    this.inputMode = 'normal';
    this.pencilMarksState = {};
    this.previousStates = [];
    observe(this.store, 'gameCode', async (change) => {
      if (change.newValue) {
        const pencilState = await this.getPencilMarkState();
        if (pencilState) {
          this.pencilMarksState = pencilState;
        }
      }
    });

    observe(this, 'boardEntries', () => {
      this.deleteOutdatedLocalEntries();
    });
  }

  setBoard(boardEntries: BoardEntry[]) {
    this.boardEntries = boardEntries;
  }

  private storePencilMarkState() {
    const pencilState = JSON.stringify({
      gameCode: this.store.gameCode,
      pencilMarks: this.pencilMarksState,
    });
    KVStorageEngine.setItem('pencils', pencilState);
  }

  private shouldDeleteLocalEntries(
    boardEntry: BoardEntry | undefined
  ): boolean {
    if (!boardEntry) {
      return false;
    }
    if (boardEntry.type == 'open') {
      if (this.store.mode == 'original') {
        return boardEntry.scorers.indexOf(this.store.playerName) >= 0;
      } else if (this.store.mode == 'hardcore') {
        return boardEntry.guesses.some(
          (guess) => guess.name == this.store.playerName
        );
      }
    }
    return false;
  }

  /**
   * Delete all localBoardEntries where the player has scored.
   *
   * @private
   * @memberof BoardStore
   */
  private deleteOutdatedLocalEntries() {
    this.localBoardEntries.forEach((entry) => {
      const position: Position = { row: entry.row, column: entry.column };
      const boardEntry = this.getBoardEntry(position);
      if (this.shouldDeleteLocalEntries(boardEntry)) {
        this.deleteLocalEntries(position);
      }
    });
  }

  private getLocalBoardEntryForPosition(
    position: Position | undefined
  ): BoardEntry | undefined {
    if (!position) {
      return;
    }
    return this.localBoardEntries.find((localBoardEntry) => {
      return (
        localBoardEntry.row == position.row &&
        localBoardEntry.column == position.column
      );
    });
  }

  async getPencilMarkState(): Promise<PencilMarkState> {
    const pencilState = await KVStorageEngine.getItem('pencils');
    if (pencilState) {
      try {
        const pencilStateObject = JSON.parse(pencilState);
        if (pencilStateObject.gameCode == this.store.gameCode) {
          return pencilStateObject.pencilMarks;
        } else {
          return {};
        }
      } catch (error) {
        return {};
      }
    }
    return {};
  }

  private getPlayerColor(playerName: string): string {
    const player = this.store.players.find((p: Player) => {
      return p.name == playerName;
    });
    if (player) {
      return player.color;
    }
    return '';
  }

  @computed get canUndo() {
    return this.previousStates.length > 0;
  }

  @computed get boardState() {
    return [...this.boardEntries, ...this.localBoardEntries].reduce(
      (memo: { [key: string]: BoardEntry }, value) => {
        const tempValue = { ...value };
        if (tempValue.type == 'open') {
          if (tempValue.scorers.length > 0) {
            let scorers = [...tempValue.scorers];
            if (this.store.hideCompleted == true) {
              scorers = scorers.filter((scorer) => {
                return this.store.completedPlayers.indexOf(scorer) < 0;
              });
            }
            if (scorers.length > 0) {
              // find scorer that isn't disqualified
              const potentialNonDisqualifiedScorer = scorers.find((scorer) => {
                return !tempValue.disqualified.includes(scorer);
              });

              if (potentialNonDisqualifiedScorer) {
                tempValue.backgroundColor = this.getPlayerColor(
                  potentialNonDisqualifiedScorer
                );
              } else {
                tempValue.backgroundColor = '';
              }
            } else {
              // don't show the value if hideCompleted is true and nobody else got the answer yet.
              tempValue.value = undefined;
            }
          } else if (tempValue.guesses.length > 0) {
            const guess = tempValue.guesses.find(
              (g) => g.name == this.store.playerName
            );
            if (guess) {
              tempValue.value = guess.value;
            }
            tempValue.backgroundColor = this.getPlayerColor(
              tempValue.guesses[0].name
            );
          }
        }
        memo[`${value.row}${value.column}`] = tempValue;
        return memo;
      },
      {}
    );
  }

  @computed get completedNumbers(): number[] {
    const numbers: number[] = [];

    for (let i = 1; i <= 9; i++) {
      const hasCompleted = !this.boardEntries.some((boardEntry) => {
        return (
          boardEntry.type == 'open' &&
          boardEntry.value == i &&
          boardEntry.scorers.indexOf(this.store.playerName) < 0
        );
      });
      if (hasCompleted) {
        numbers.push(i);
      }
    }

    return numbers;
  }

  getBoardEntry(position: Position): BoardEntry | undefined {
    return this.boardEntries.find((entry: BoardEntry) => {
      return entry.column == position.column && entry.row == position.row;
    });
  }

  private deleteLocalEntries(position: Position) {
    this.localBoardEntries = this.localBoardEntries.filter(
      (entry: BoardEntry) => {
        return !(entry.row == position.row && entry.column == position.column);
      }
    );
  }

  clearAllLocalEntries() {
    this.localBoardEntries = [];
  }

  clearAllPencils() {
    KVStorageEngine.setItem('pencils', '');
    this.pencilMarksState = {};
  }

  static playerHasScoredForBlock(playerName: string, entry: BoardEntry) {
    if (entry.type == 'open') {
      return entry.scorers.indexOf(playerName) >= 0;
    }
    return false;
  }

  static getPlayerGuessForBlock(
    playerName: string,
    entry: BoardEntry
  ): Guess | undefined {
    if (entry.type == 'open') {
      return entry.guesses.find((g) => g.name == playerName);
    }
    return undefined;
  }

  async handleUndo() {
    if (this.previousStates.length > 0) {
      const previousState = this.previousStates.pop();
      if (!previousState) {
        return;
      }
      this.selectedBlock = previousState.selectedBlock;

      runInAction(async () => {
        if (
          previousState.type == 'answer' &&
          previousState.value != undefined
        ) {
          await this.handleNumberSelected({
            value: previousState.value!,
            selected: previousState.selectedBlock,
            isUndo: true,
          });
        }
        this.pencilMarksState = JSON.parse(previousState.pencils);
        await this.storePencilMarkState();
      });
    }
    GAEvent('undo');
  }

  async handleNumberSelected(options: HandleNumberSelectedOptions) {
    if (!this.selectedBlock) {
      return;
    }
    if (this.store.mode == 'original') {
      return this.handleOriginalModeNumberSelected(options);
    } else {
      return this.handleHardcoreModeNumberSelected(options);
    }
  }

  private async handleOriginalModeNumberSelected(
    options: HandleNumberSelectedOptions
  ) {
    const value = options.value;
    const selectedBlock = options.selected ?? this.selectedBlock;

    const selectedBlockCoords = BoardStore.getPositionCoords(
      selectedBlock
    ) as Position;
    const currentBoardEntry = this.getBoardEntry(selectedBlockCoords);

    if (!currentBoardEntry || currentBoardEntry.type == 'closed') {
      return;
    }

    if (value == 0) {
      const localBoardEntry = this.getLocalBoardEntryForPosition(
        selectedBlockCoords
      );
      if (localBoardEntry) {
        this.deleteLocalEntries(selectedBlockCoords);
        return;
      } else if (this.pencilMarksState[selectedBlock]?.length > 0) {
        this.pencilMarksState[selectedBlock] = observable.array([]);
        return this.storePencilMarkState();
      }
      return;
    }

    if (this.inputMode == 'notes') {
      if (this.pencilMarksState[selectedBlock]) {
        const index = this.pencilMarksState[selectedBlock].indexOf(value);
        if (index >= 0) {
          this.pencilMarksState[selectedBlock].splice(index, 1);
        } else {
          this.pencilMarksState[selectedBlock].push(value);
        }
      } else {
        this.pencilMarksState[selectedBlock] = observable.array([]);
        this.pencilMarksState[selectedBlock].push(value);
      }
      return this.storePencilMarkState();
    }

    const playerAlreadyScored = BoardStore.playerHasScoredForBlock(
      this.store.playerName,
      currentBoardEntry
    );
    if (playerAlreadyScored) {
      return;
    }

    const tempBoardEntry: BoardEntry = {
      row: selectedBlockCoords.row,
      column: selectedBlockCoords.column,
      backgroundColor: '',
      value: currentBoardEntry.value,
      guess: value,
      type: 'guess',
    };
    this.localBoardEntries.push(tempBoardEntry);
    const response = await API.FillBlock(
      this.store.gameCode,
      this.store.playerName,
      selectedBlock,
      value
    );

    if (response.correct == true) {
      this.deleteLocalEntries(selectedBlockCoords);
      this.localBoardEntries.push(response.boardEntry);
      if (!options.isUndo) {
        this.deletePencilMarksThatSeePosition(
          {
            row: selectedBlockCoords.row,
            column: selectedBlockCoords.column,
          },
          value
        );
      }
      GAEvent('cell_correct');
    } else {
      GAEvent('cell_incorrect');
    }
  }

  private async handleHardcoreModeNumberSelected(
    options: HandleNumberSelectedOptions
  ) {
    if (this.store.currentPlayer?.completedAt) {
      console.log('Player already completed');
      this.clearAllLocalEntries();
      return;
    }
    const value = options.value;
    let selectedBlock = options.selected ?? this.selectedBlock;

    let selectedBlockCoords = BoardStore.getPositionCoords(
      selectedBlock
    ) as Position;
    let currentBoardEntry = this.getBoardEntry(selectedBlockCoords);

    if (!currentBoardEntry || currentBoardEntry.type == 'closed') {
      return;
    }
    if (options.isUndo) {
      let tempBoardEntry: BoardEntry = {
        row: selectedBlockCoords.row,
        column: selectedBlockCoords.column,
        backgroundColor: '',
        value: currentBoardEntry.value,
        guess: value,
        type: 'guess',
      };
      this.localBoardEntries.push(tempBoardEntry);
      const response = await API.FillBlock(
        this.store.gameCode,
        this.store.playerName,
        selectedBlock,
        value
      );

      if (response.correct == true) {
        this.deleteLocalEntries(selectedBlockCoords);
        if (value != 0) {
          this.localBoardEntries.push(response.boardEntry);
        }
      }

      return;
    }

    GAEvent('hardcore_number_entered');

    const currentGuess = BoardStore.getPlayerGuessForBlock(
      this.store.playerName,
      currentBoardEntry
    );

    if (value == 0) {
      if (currentGuess) {
        this.deleteLocalEntries(selectedBlockCoords);
        const previousState: PreviousState = {
          pencils: JSON.stringify(this.pencilMarksState),
          selectedBlock: selectedBlock,
          value: currentGuess.value,
          type: 'answer',
        };
        this.addPreviousState(previousState);

        await API.FillBlock(
          this.store.gameCode,
          this.store.playerName,
          selectedBlock,
          value
        );
      } else if (this.pencilMarksState[selectedBlock]?.length > 0) {
        this.addPreviousState({
          type: 'pencil',
          selectedBlock,
          pencils: JSON.stringify(this.pencilMarksState),
        });
        this.pencilMarksState[selectedBlock] = observable.array([]);
        await this.storePencilMarkState();
      } else {
        this.deleteLocalEntries(selectedBlockCoords);
      }
      return;
    }

    if (this.inputMode == 'notes') {
      this.addPreviousState({
        type: 'pencil',
        selectedBlock,
        pencils: JSON.stringify(this.pencilMarksState),
      });
      if (this.pencilMarksState[selectedBlock]) {
        const index = this.pencilMarksState[selectedBlock].indexOf(value);
        if (index >= 0) {
          this.pencilMarksState[selectedBlock].splice(index, 1);
        } else {
          this.pencilMarksState[selectedBlock].push(value);
        }
      } else {
        this.pencilMarksState[selectedBlock] = observable.array([]);
        this.pencilMarksState[selectedBlock].push(value);
      }
      return this.storePencilMarkState();
    } else {
      if (this.store.currentPlayer?.completedAt) {
        return;
      }
      let tempBoardEntry: BoardEntry = {
        row: selectedBlockCoords.row,
        column: selectedBlockCoords.column,
        backgroundColor: '',
        value: currentBoardEntry.value,
        guess: value,
        type: 'guess',
      };

      this.addPreviousState({
        pencils: JSON.stringify(this.pencilMarksState),
        selectedBlock: selectedBlock,
        value: 0,
        type: 'answer',
      });

      this.localBoardEntries.push(tempBoardEntry);

      const response = await API.FillBlock(
        this.store.gameCode,
        this.store.playerName,
        selectedBlock,
        value
      );

      if (response.correct == true) {
        this.deleteLocalEntries(selectedBlockCoords);
        this.localBoardEntries.push(response.boardEntry);
        if (this.store.settingsStore.automaticHidePencils) {
          this.deletePencilMarksThatSeePosition(
            {
              row: selectedBlockCoords.row,
              column: selectedBlockCoords.column,
            },
            value
          );
        }
      }
    }
  }

  private addPreviousState(previousState: PreviousState) {
    if (this.previousStates.length == 0 || previousState.type == 'pencil') {
      this.previousStates.push(previousState);
      return;
    } else {
      const lastPreviousState = this.previousStates[
        this.previousStates.length - 1
      ];
      if (
        !(
          lastPreviousState.selectedBlock == previousState.selectedBlock &&
          lastPreviousState.value == previousState.value &&
          lastPreviousState.type == previousState.type
        )
      ) {
        this.previousStates.push(previousState);
      }
    }
    while (
      this.previousStates.filter(
        (previousState) => previousState.type == 'answer'
      ).length > MAX_UNDO_DEPTH
    ) {
      this.previousStates.shift();
    }
  }

  // start top left with 1 and contiue as if reading a book with numbering to 9
  /*
  | 1 | 2 | 3 |
  | 4 | 5 | 6 |
  | 7 | 8 | 9 |
  */
  public static getPositionBigBlock(position: Position): number {
    if (position.row <= 3) {
      if (position.column <= 3) {
        return 1;
      } else if (position.column <= 6) {
        return 2;
      }
      return 3;
    } else if (position.row <= 6) {
      if (position.column <= 3) {
        return 4;
      } else if (position.column <= 6) {
        return 5;
      }
      return 6;
    }
    if (position.column <= 3) {
      return 7;
    } else if (position.column <= 6) {
      return 8;
    }
    return 9;
  }

  deletePencilMarksThatSeePosition(position: Position, value: number): void {
    const postionsToClear = this.boardEntries
      .filter((entry) => {
        return (
          entry.type == 'open' &&
          (entry.row == position.row ||
            entry.column == position.column ||
            BoardStore.getPositionBigBlock(entry) ==
              BoardStore.getPositionBigBlock(position))
        );
      })
      .map((entry) => {
        return BoardStore.getPositionString({
          row: entry.row,
          column: entry.column,
        });
      });
    postionsToClear.forEach((positionString) => {
      if (this.pencilMarksState[positionString]) {
        let index = this.pencilMarksState[positionString].indexOf(value);
        if (index >= 0) {
          this.pencilMarksState[positionString].splice(index, 1);
        }
      }
    });
    this.storePencilMarkState();
  }

  public static getPositionCoords(block?: string): Position | undefined {
    if (block && block.length == 2) {
      return {
        row: parseInt(block.charAt(0)),
        column: parseInt(block.charAt(1)),
      };
    }
    return undefined;
  }

  public static getPositionString(position: Position): string {
    if (!position) {
      return '';
    }
    return `${position.row}${position.column}`;
  }

  handleArrowPressed(arrow: string) {
    const currentPosition = BoardStore.getPositionCoords(
      this.selectedBlock
    ) ?? {
      row: 1,
      column: 1,
    };
    switch (arrow) {
      case 'ArrowLeft':
        currentPosition.column = Math.max(currentPosition.column - 1, 1);
        break;
      case 'ArrowRight':
        currentPosition.column = Math.min(currentPosition.column + 1, 9);
        break;
      case 'ArrowUp':
        currentPosition.row = Math.max(currentPosition.row - 1, 1);
        break;
      case 'ArrowDown':
        currentPosition.row = Math.min(currentPosition.row + 1, 9);
        break;
      default:
        break;
    }
    this.selectedBlock = BoardStore.getPositionString(currentPosition);
  }

  clearBoard() {
    this.clearAllLocalEntries();
    this.clearAllPencils();
    this.previousStates = [];
  }
}
