import React, { FC, useState } from 'react';
import {
  Menu,
  MenuItem,
  MenuButton,
  SubMenu,
  MenuDivider,
  MenuHeader,
} from '@szhsin/react-menu';
import Toggle from 'react-toggle';
import { runInAction } from 'mobx';
import { Store } from '../Stores/Store';
import CopyToClipboard from 'react-copy-to-clipboard';
import { PlayerIndicator } from './PlayerIndicator';
import { GAEvent } from '../lib/GoogleAnalytics';
import { AsyncButton } from '../widgets/AsyncButton';
import { observer } from 'mobx-react';

export interface UserMenuProps {
  store: Store;
}

const canShare: boolean = !!navigator.share;

export const UserMenu: FC<UserMenuProps> = observer(({ store }) => {
  const [copied, setCopied] = useState<boolean | null>(null);

  return (
    <Menu
      menuButton={
        <MenuButton>
          <div className="flex items-center justify-center">
            <PlayerIndicator
              playerName={store.playerName}
              color={store.myColor!}
            />
            <i className="ri-menu-fill ml-4"></i>
          </div>
        </MenuButton>
      }
      menuStyle={{
        backgroundColor: 'var(--background-grey-100)',
        color: 'var(--text-grey-600)',
      }}
    >
      {store.mode === 'original' ? (
        <>
          <OriginalModeSettings store={store} />
          <MenuDivider />
        </>
      ) : null}

      {store.mode == 'hardcore' && (
        <>
          <HardcoreModeSettings store={store} />
          <MenuDivider />
        </>
      )}

      <MenuHeader>Game: {store.gameCode}</MenuHeader>

      <MenuItem
        onClick={(e) => {
          e.keepOpen = false;

          runInAction(
            () => (store.settingsStore.darkMode = !store.settingsStore.darkMode)
          );
        }}
      >
        <div className={'flex gap-2 items-center'}>
          <div className={'flex flex-col'} style={{ width: '200px' }}>
            <div className={'font-bold'}>Dark mode</div>
            <div className={'text-sm'}>
              {store.settingsStore.darkMode
                ? 'Dark mode is enabled.'
                : 'Dark mode is not enabled.'}
            </div>
          </div>
          <div>
            <Toggle
              checked={store.settingsStore.darkMode == true}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();

                runInAction(
                  () =>
                    (store.settingsStore.darkMode = !store.settingsStore
                      .darkMode)
                );
              }}
            />
          </div>
        </div>
      </MenuItem>
      <MenuItem
        onClick={(e) => {
          e.keepOpen = true;

          const newValue = !store.settingsStore.dynamicNotificationsEnabled;
          sendGAEventNotificationsEnabled(newValue);

          runInAction(
            () => (store.settingsStore.dynamicNotificationsEnabled = newValue)
          );
        }}
      >
        <div className={'flex gap-2 items-center'}>
          <div className={'flex flex-col'} style={{ width: '200px' }}>
            <div className={'font-bold'}>Notifications</div>
            <div className={'text-sm'}>
              {store.settingsStore.dynamicNotificationsEnabled
                ? 'Score update notifications are enabled.'
                : 'Score update notifications are not enabled.'}
            </div>
          </div>
          <div>
            <Toggle
              checked={store.settingsStore.dynamicNotificationsEnabled}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();

                const newValue = !store.settingsStore
                  .dynamicNotificationsEnabled;
                sendGAEventNotificationsEnabled(newValue);

                runInAction(
                  () =>
                    (store.settingsStore.dynamicNotificationsEnabled = newValue)
                );
              }}
            />
          </div>
        </div>
      </MenuItem>

      <MenuItem
        onClick={(e) => {
          e.keepOpen = true;
        }}
      >
        <CopyToClipboard
          text={store.gameLink}
          onCopy={() => {
            setCopied(true);
            setTimeout(() => {
              setCopied(null);
              GAEvent('game_link_copied__user_menu');
            }, 1500);
          }}
        >
          <div className={'flex gap-2 items-center'}>
            <div className={'flex flex-col'} style={{ width: '200px' }}>
              <div className={'font-bold'}>Copy Invite Link</div>
              <div className={'mt-1'}>
                {copied ? (
                  <div
                    className={'flex items-center text-sm'}
                    style={{
                      color: 'var(--toggle-dark-green)',
                    }}
                  >
                    Copied to your clipboard
                  </div>
                ) : (
                  <div className={'text-sm'}>{store.gameLink}</div>
                )}
              </div>
            </div>
          </div>
        </CopyToClipboard>
      </MenuItem>
      <MenuItem
        onClick={(e) => {
          e.keepOpen = true;
          sendGAEventDiscordLinkClicked();
          window.open(store.homePageLink, '_self');
        }}
      >
        <div className={'flex gap-2 items-center'}>
          <div className={'flex flex-col w-4/5'}>
            <div className={'font-bold text-red-600'}>Exit Game</div>
            <div className={'text-sm'}>
              Don't worry, you can join again at any time.
            </div>
          </div>
        </div>
      </MenuItem>
      <MenuDivider />
      <MenuHeader>Platform</MenuHeader>
      <MenuItem
        onClick={(e) => {
          e.keepOpen = true;
          sendGAEventDiscordLinkClicked();
          window.open('https://discord.gg/hbY5pBYSkh', '_blank');
        }}
      >
        <div className={'flex gap-2 items-center'}>
          <div className={'flex flex-col'}>
            <div className="flex gap-2">
              <div className={'flex flex-col w-4/5'}>
                <div
                  className={'font-bold'}
                  style={{ color: 'var(--text-grey-500)' }}
                >
                  Join Discord Server
                </div>
                <div className={'text-sm'}>
                  Find friends, learn skills or leave feedback.
                </div>
              </div>
              <i
                className="ri-external-link-line"
                style={{ color: 'var(--text-grey-500)' }}
              />
            </div>
          </div>
        </div>
      </MenuItem>
      {canShare && (
        <MenuItem
          onClick={async (e) => {
            e.keepOpen = true;
            try {
              await navigator.share({
                title: 'UsDoku',
                text: 'Join me in an UsDoku game',
                url: store.shareGameLink,
              });
              GAEvent('shared_game_success');
            } catch (error) {
              GAEvent('shared_game_failure');
            }
          }}
        >
          <div className={'flex gap-2 items-center'}>
            <div className={'flex flex-col'}>
              <div className="flex gap-2">
                <div className={'flex flex-col'} style={{ width: '200px' }}>
                  <div className={'font-bold'}>Share this game</div>
                  <div className={'text-sm'}>Invite someone to join</div>
                </div>
              </div>
            </div>
          </div>
        </MenuItem>
      )}
    </Menu>
  );
});

export const OriginalModeSettings = observer((props: UserMenuProps) => {
  return (
    <>
      <MenuHeader>UsDoku Original</MenuHeader>
      <MenuItem
        onClick={(e) => {
          e.keepOpen = true;

          const newDisabledValue = !props.store.settingsStore.colorsDisabled;
          sendGAEventColorsDisabled(newDisabledValue);

          runInAction(
            () => (props.store.settingsStore.colorsDisabled = newDisabledValue)
          );
        }}
      >
        <div className={'flex gap-2 items-center'}>
          <div className={'flex flex-col'} style={{ width: '200px' }}>
            <div className={'font-bold'}>Colors</div>
            <div className={'text-sm'}>
              {props.store.settingsStore.colorsDisabled
                ? 'Colors are not enabled on the game board.'
                : 'Colors are enabled on the game board.'}
            </div>
          </div>
          <div>
            <Toggle
              checked={!props.store.settingsStore.colorsDisabled}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();

                const newDisabledValue = !props.store.settingsStore
                  .colorsDisabled;
                sendGAEventColorsDisabled(newDisabledValue);

                runInAction(
                  () =>
                    (props.store.settingsStore.colorsDisabled = newDisabledValue)
                );
              }}
            />
          </div>
        </div>
      </MenuItem>
    </>
  );
});

export const HardcoreModeSettings = observer((props: UserMenuProps) => {
  return (
    <>
      <MenuHeader>UsDoku Hardcore</MenuHeader>
      <MenuItem
        onClick={(e) => {
          e.keepOpen = false;
          const confirmed = confirm(
            'Are you sure you want to clear your board? This action cannot be undone.'
          );
          if (confirmed) {
            GAEvent('game_reset');
            props.store.resetGame();
          }
        }}
      >
        <div className={'flex gap-2 items-center'}>
          <div className={'flex flex-col'} style={{ width: '250px' }}>
            <div className={'font-bold'}>Clear Your Board</div>
            <div className={'text-sm'}>
              Did you make a huge mistake? Clear all your entries and start
              anew.
            </div>
          </div>
        </div>
      </MenuItem>
      <MenuItem
        onClick={(e) => {
          e.keepOpen = true;

          const newValue = !props.store.settingsStore.automaticHidePencils;
          // sendGAEventNotificationsEnabled(newValue);

          runInAction(
            () => (props.store.settingsStore.automaticHidePencils = newValue)
          );
        }}
      >
        <div className={'flex gap-2 items-center'}>
          <div className={'flex flex-col'} style={{ width: '200px' }}>
            <div className={'font-bold'}>Automatically Remove Notes</div>
            <div className={'text-sm'}>
              {props.store.settingsStore.automaticHidePencils
                ? 'Pencil notes are automatically removed.'
                : 'Pencil notes are not automatically removed.'}
            </div>
          </div>
          <div>
            <Toggle
              checked={props.store.settingsStore.automaticHidePencils}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();

                const newValue = !props.store.settingsStore
                  .automaticHidePencils;

                runInAction(
                  () =>
                    (props.store.settingsStore.automaticHidePencils = newValue)
                );
              }}
            />
          </div>
        </div>
      </MenuItem>
    </>
  );
});

function sendGAEventColorsDisabled(newDisabledValue: boolean) {
  if (newDisabledValue) {
    GAEvent('colors_disabled');
  } else {
    GAEvent('colors_enabled');
  }
}

function sendGAEventNotificationsEnabled(newEnabledValue: boolean) {
  if (newEnabledValue) {
    GAEvent('notifications_enabled');
  } else {
    GAEvent('notifications_disabled');
  }
}

function sendGAEventDiscordLinkClicked() {
  GAEvent('discord_joined');
}
