import { observable, computed, runInAction } from 'mobx';
import { DynamicNotificationMessage } from './Events';
import { addSeconds, isBefore } from 'date-fns';
import { bisect } from '../lib/util';

const ONE_SECOND = 1000;
const DEFAULT_DISPLAY_TIME = 2 * ONE_SECOND;

export type DynamicNotification = {
  priority: number;
  expiresAt: Date;
  payload: DynamicNotificationMessage;
};

export class DynamicNotificationsStore {
  @observable current?: DynamicNotification;
  private queue: DynamicNotification[] = [];
  private timeout: NodeJS.Timeout;

  constructor() {
    this.timeout = setTimeout(this.timeoutHandler, ONE_SECOND);
  }

  @computed
  get currentHash() {
    if (!this.current) {
      return undefined;
    }

    return dynamicNotificationAbbreviatedString(this.current.payload);
  }

  timeoutHandler = () => {
    const now = new Date();

    const [nonExpired] = bisect(this.queue, ({ expiresAt }) =>
      isBefore(now, expiresAt)
    );

    const [max, rest] = splitIntoMaxAndRest(nonExpired);

    runInAction(() => {
      if (
        max &&
        this.currentHash !== dynamicNotificationAbbreviatedString(max.payload)
      ) {
        this.current = max;
      } else {
        // Don't show the same message twice in a row, rather show nothing.
        this.current = undefined;
      }

      this.queue = rest;
    });

    const nextTimeoutIn = this.current ? DEFAULT_DISPLAY_TIME : ONE_SECOND;
    this.timeout = setTimeout(this.timeoutHandler, nextTimeoutIn);
  };

  addToQueue(payload: DynamicNotificationMessage): void {
    const toAdd = {
      payload,
      priority: basePriority(payload.type) + payload.relativePriority,
      expiresAt: addSeconds(new Date(), 5),
    };

    this.queue.push(toAdd);
  }
}

function dynamicNotificationAbbreviatedString(
  message: DynamicNotificationMessage
) {
  const parts: string[] = [message.type, message.player.name];
  switch (message.type) {
    case 'score-added':
      parts.push(message.value.toString());
      break;
    case 'leader-update':
    case 'player-milestone-update':
    case 'high-priority-generic-update':
    case 'low-priority-generic-update':
      parts.push(message.update);
      break;
  }

  return parts.join('|');
}

// Static for now, but can make it configurable per user in the future.
function basePriority(type: DynamicNotificationMessage['type']): number {
  if (type === 'high-priority-generic-update') {
    return 400;
  }
  if (type === 'leader-update') {
    return 300;
  }
  if (type === 'player-milestone-update') {
    return 200;
  }
  if (type === 'low-priority-generic-update') {
    return 100;
  }

  return 0;
}

function splitIntoMaxAndRest(
  list: DynamicNotification[]
): [DynamicNotification | undefined, DynamicNotification[]] {
  let max: DynamicNotification | undefined;
  const rest: DynamicNotification[] = [];

  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    if (!max) {
      max = item;
      continue;
    }

    if (item.priority > max.priority) {
      rest.push(max);
      max = item;
    } else {
      rest.push(item);
    }
  }
  return [max, rest];
}
