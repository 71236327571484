import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { GameActionButton } from './GameActionButton';
import { Eraser } from './Icons/Eraser';
import { Pencil } from './Icons/Pencil';
import { Score } from './Icons/Score';
import { Chat } from './Icons/Chat';
import { Undo } from './Icons/Undo';
import { ChatContainer } from './ChatContainer';

export type InputMode = 'normal' | 'notes';

export interface InputModeSelectorProps {
  background: string;
  size: number;
  mode: string;
  inputMode: InputMode;
  canUndo: boolean;
  inputModeChanged?: (mode: InputMode) => void;
  onMessageSelected?: (message: string) => void;
  onErase?: () => void;
  onUndo?: () => void;
  playerScore: number;
  playerColor: string;
  numberOfPlayers: number;
  validChatOptions: any[];
}

export const InputButton = styled.div((props: any) => {
  return {
    background: props.active ? '#1a1b1d' : '',
    color: props.active ? 'white' : '#d9dcdc',
    width: '100%',
    height: `${props.size}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #d9dcdc',
    fontSize: `${props.size * 0.5}px`,
  };
});

export const InputSelectorContainer = styled.div((props: any) => {
  return {
    width: `${props.size}px`,
    userSelect: 'none',
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingTop: '8px',
    paddingBottom: '8px',
    backgroundColor: props.background,
  };
});

function handleInputButtonPressed(
  props: InputModeSelectorProps,
  value: InputMode
) {
  props.inputModeChanged && props.inputModeChanged(value);
}

function generateInputButtons(props: InputModeSelectorProps) {
  const scoreText =
    props.mode == 'original'
      ? `Score: ${props.playerScore}`
      : `${props.playerScore}%`;
  return [
    <GameActionButton
      key="score"
      size={props.size}
      icon={<Score color={props.playerColor} />}
      text={scoreText}
      inputMode={props.inputMode}
    />,
    <ChatContainer
      trigger={
        <GameActionButton
          key="chat"
          size={props.size}
          icon={<Chat color={props.playerColor} />}
          text={<span>Chat</span>}
          inputMode={props.inputMode}
        />
      }
      options={props.validChatOptions
        .filter((c) => ['all', 'game'].includes(c.where))
        .map((c) => c.text)}
      onSelect={(message) => {
        props.onMessageSelected && props.onMessageSelected(message);
      }}
    />,
    props.mode == 'hardcore' && (
      <GameActionButton
        key="undo"
        size={props.size}
        icon={
          <Undo
            undoColor={props.canUndo ? null : 'var(--number-pencil-color)'}
          />
        }
        text={'Undo'}
        inputMode={props.inputMode}
        onClick={() => {
          props.onUndo && props.onUndo();
        }}
      />
    ),
    <GameActionButton
      key="pencil"
      size={props.size}
      icon={
        <Pencil
          pencilFillColor={props.inputMode == 'notes' ? props.playerColor : ''}
        />
      }
      text={'Notes'}
      inputMode={props.inputMode}
      onClick={() => {
        props.inputModeChanged &&
          props.inputModeChanged(
            props.inputMode == 'notes' ? 'normal' : 'notes'
          );
      }}
      pill={
        props.inputMode == 'notes'
          ? {
              text: 'On',
              backgroundColor: 'var(--pencil-active-color)',
              textColor: 'white',
            }
          : {
              text: 'Off',
              backgroundColor: 'var(--pencil-inactive-color)',
              textColor: 'var(--number-correct-color)',
            }
      }
    />,
    <GameActionButton
      key="eraser"
      size={props.size}
      icon={<Eraser eraserColor={props.playerColor} />}
      text={'Erase'}
      inputMode={props.inputMode}
      onClick={() => {
        props.onErase && props.onErase();
      }}
    />,
  ];
}

export const InputModeSelector: React.FC<InputModeSelectorProps> = observer(
  (props) => {
    const handleKeyPressed = (ev: KeyboardEvent) => {
      if (ev.code == 'Space') {
        ev.preventDefault();
        let newMode: InputMode;
        if (props.inputMode === 'normal') {
          newMode = 'notes';
        } else {
          newMode = 'normal';
        }

        handleInputButtonPressed(props, newMode);
      }
    };
    useEffect(() => {
      document.addEventListener('keydown', handleKeyPressed);

      return () => {
        document.removeEventListener('keydown', handleKeyPressed);
      };
    });
    return (
      <InputSelectorContainer background={props.background} size={props.size}>
        {generateInputButtons(props)}
      </InputSelectorContainer>
    );
  }
);
