import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { InputMode } from './InputModeSelector';

export interface InputGridProps {
  background?: string;
  size: number;
  inputMode: InputMode;
  completedNumbers: number[];
  onNumberPressed?: (value: number) => void;
  onArrowPressed?: (arrow: string) => void;
}

export const InputGridNumber = styled.div((props: any) => {
  return {
    background: props.background,
    width: props.size,
    height: props.size,
    maxWidth: props.size,
    maxHeight: props.size,
    userSelect: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: props.size * 0.6,
    fontFamily: 'Source Sans Pro, sans-serif',
    color: props.completed
      ? 'var(--number-pencil-color)'
      : 'var(--number-correct-color)',
    cursor: 'pointer',
    borderRadius: '4px',
    '@media (hover:hover)': {
      '&:hover': {
        background: 'var(--block-hover-color)',
      },
    },
  };
});

export const InputGridContainer = styled.div((props: any) => {
  return {
    background: props.background,
    width: '100%',
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  };
});

function generateInputGrid(props: InputGridProps) {
  let grid = [];
  for (let i = 1; i <= 9; i++) {
    grid.push(
      <InputGridNumber
        key={i}
        size={props.size}
        background={props.background}
        inputMode={props.inputMode}
        completed={props.completedNumbers.indexOf(i) >= 0}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          props.onNumberPressed && props.onNumberPressed(i);
        }}
      >
        {i}
      </InputGridNumber>
    );
  }
  return grid;
}

export const InputGrid: React.FC<InputGridProps> = observer(
  (props: InputGridProps) => {
    const handleKeyboardEvent = (ev: KeyboardEvent) => {
      if (ev.key == 'Backspace' && props.onNumberPressed) {
        props.onNumberPressed(0);
      }
      let num = parseInt(ev.key);
      if (!isNaN(num) && num >= 0 && num <= 9 && props.onNumberPressed) {
        props.onNumberPressed(num);
      }
      if (props.onArrowPressed) {
        ev.preventDefault();
        if (ev.key.indexOf('Arrow') == 0) {
          props.onArrowPressed(ev.key as any);
        }
        switch (ev.key.toLowerCase()) {
          case 'a':
            props.onArrowPressed('ArrowLeft');
            break;
          case 'd':
            props.onArrowPressed('ArrowRight');
            break;
          case 's':
            props.onArrowPressed('ArrowDown');
            break;
          case 'w':
            props.onArrowPressed('ArrowUp');
            break;
          default:
            break;
        }
      }
    };

    useEffect(() => {
      document.addEventListener('keydown', handleKeyboardEvent);

      return () => {
        document.removeEventListener('keydown', handleKeyboardEvent);
      };
    });

    return (
      <InputGridContainer background={props.background} size={props.size}>
        {generateInputGrid(props)}
      </InputGridContainer>
    );
  }
);
