import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

export type StorageKeys =
  | 'playerName'
  | 'recentGameCode'
  | 'gameHasStartedCode'
  | 'pencils'
  | 'visibility'
  | 'difficulty'
  | 'mode'
  | 'theme'
  | 'settings__colors-disabled'
  | 'settings__dynamic-notifications-enabled'
  | 'settings__automatic-hide-pencils';

export class KVStorageEngine {
  static setItem(key: StorageKeys, value: string): Promise<void> {
    return Storage.set({ key, value });
  }

  static async getItem(key: StorageKeys): Promise<string | null> {
    let retrieved = await Storage.get({ key });
    return retrieved.value;
  }

  static removeItem(key: StorageKeys): Promise<void> {
    return Storage.remove({ key });
  }

  static async keys(): Promise<StorageKeys[]> {
    let retrieved = await Storage.keys();
    return retrieved.keys as StorageKeys[];
  }

  static clear(): Promise<void> {
    return Storage.clear();
  }
}
