import React, { FC } from 'react';
import { useWindowSize } from 'react-use';
import { SendChatView } from './Views/SendChat';
import Popup from 'reactjs-popup';
import { observer } from 'mobx-react';
import { GAEvent } from '../lib/GoogleAnalytics';
import { PopupActions } from 'reactjs-popup/dist/types';

export interface ChatContainerProps {
  options: string[];
  onSelect: (message: string) => void;
  trigger?: JSX.Element;
}

export const ChatContainer: FC<ChatContainerProps> = observer((props) => {
  const { width } = useWindowSize();
  const modal = width <= 800;

  return (
    <Popup
      trigger={
        props.trigger ? (
          props.trigger
        ) : (
          <i className="ri-chat-3-line text-xl cursor-pointer hover:text-purple-400"></i>
        )
      }
      position="bottom center"
      modal={true}
      lockScroll={true}
      closeOnDocumentClick={true}
    >
      {(closePopup: any) => {
        return (
          <SendChatView
            onClose={closePopup}
            options={props.options}
            onSelect={(option) => {
              closePopup();
              GAEvent('message_sent');
              props.onSelect(option);
            }}
          />
        );
      }}
    </Popup>
  );
});
