import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';

const ANSWER_FONT_RATIO = 0.6;
const PENCIL_FONT_RATIO = 0.26;

export interface BorderTypes {
  top: string;
  right: string;
  bottom: string;
  left: string;
}

export interface SmallBlockProps {
  background: string;
  size: number; // square
  value?: number;
  selectedValue?: number;
  dark?: boolean;
  pencilMarks?: number[];
  borders?: BorderTypes;
  onSelect?: () => void;
  color?: string;
}

export const Block = styled.div((props: SmallBlockProps) => {
  return {
    '@media (hover:hover)': {
      '&:hover': {
        background: 'var(--block-hover-color)',
      },
    },
    background: props.background,
    width: props.size,
    height: props.size,
    maxWidth: props.size,
    maxHeight: props.size,
    overflow: 'hidden',
    userSelect: 'none',
    borderTop: props.borders?.top,
    borderBottom: props.borders?.bottom,
    borderRight: props.borders?.right,
    borderLeft: props.borders?.left,
    boxSizing: 'content-box',
  };
});

export const BlockAnswer = styled.div((props: any) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: Math.ceil(props.size * ANSWER_FONT_RATIO),
  fontFamily: 'var(--game-font)',
  fontWeight: props.selection == true ? 1000 : 300,
  textShadow:
    props.selection && props.dark
      ? '-1px 1px 0 var(--background-grey-300)'
      : '',
  color:
    props.color ??
    (props.selection == true
      ? 'var(--number-selected-color)'
      : 'var(--number-correct-color)'),
}));

export const PencilBlock = styled.div((props: any) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  width: '100%',
  height: '100%',
}));

export const PencilMark = styled.div((props: any) => ({
  width: props.size / 3,
  height: props.size / 3,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: Math.ceil(props.size * PENCIL_FONT_RATIO),
  fontFamily: 'var(--game-font)',
  fontWeight: props.selection == true ? 1000 : 300,
  color:
    props.selection == true
      ? 'var(--number-selected-color)'
      : 'var(--number-pencil-color)',
  border:
    props.selection == true ? '0.5px solid var(--number-pencil-color)' : '',
  borderRadius: '50%',
}));

function getPencilMarks(props: SmallBlockProps) {
  let marks = [];
  for (let i = 1; i <= 9; i++) {
    marks.push(
      <PencilMark
        selection={
          props.selectedValue &&
          props.selectedValue == i &&
          props.pencilMarks &&
          props.pencilMarks.indexOf(i) >= 0
        }
        key={i}
        size={props.size}
      >
        {props.pencilMarks && props.pencilMarks.indexOf(i) >= 0 ? i : ' '}
      </PencilMark>
    );
  }
  return marks;
}

function getBlockContent(props: SmallBlockProps) {
  if (props.value) {
    const selection = props.selectedValue == props.value;
    const color = selection
      ? 'var(--number-selected-color)'
      : props.color || 'var(--number-correct-color)';
    return (
      <BlockAnswer
        selection={selection}
        size={props.size}
        color={color}
        dark={props.dark}
      >
        {props.value}
      </BlockAnswer>
    );
  } else {
    return <PencilBlock>{getPencilMarks(props)}</PencilBlock>;
  }
}

export const SmallBlock: React.FC<SmallBlockProps> = observer((props) => {
  return (
    <Block
      background={props.background}
      size={props.size}
      borders={props.borders}
      onMouseDown={(event) => {
        // Duplicated for now. Will just double check that this works on touch screens
        event.preventDefault();
        event.stopPropagation();
        props.onSelect && props.onSelect();
      }}
      onClick={(event) => {
        // prevent zooming on double tap in game at least:
        event.preventDefault();
        event.stopPropagation();
        props.onSelect && props.onSelect();
      }}
    >
      {getBlockContent(props)}
    </Block>
  );
});
