import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { InputMode } from './InputModeSelector';

export interface GameActionButtonProps {
  background?: string;
  size: number;
  icon: any;
  inputMode: InputMode;
  text: string | JSX.Element;
  pill?: { text: string; backgroundColor: string; textColor: string };
  onClick?: () => void;
}

export const ActionButton = styled.div((props: any) => {
  return {
    background: props.background,
    width: props.size,
    height: props.size,
    maxWidth: props.size,
    maxHeight: props.size,
    userSelect: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: props.size * 0.3,
    fontFamily: 'Source Sans Pro, sans-serif',
    color: 'var(--number-correct-color)',
  };
});

export const SvgContainer = styled.div((props: any) => {
  return {
    width: Math.floor(props.size * 0.1),
    height: Math.floor(props.size * 0.1),
    alignSelf: 'center',
    position: 'relative',
  };
});

export const TextContainer = styled.div((props: any) => {
  return {
    alignSelf: 'center',
    color: 'var(--number-correct-color)',
    fontSize: `${Math.floor(props.size * 0.2 * 0.2)}px`,
  };
});

export const PillContainer = styled.div((props: any) => {
  let fontSize = Math.floor(props.size * 0.1 * 0.2);
  return {
    position: 'absolute',
    top: '60%',
    right: '10%',
    fontSize: `${fontSize}px`,
    lineHeight: `${fontSize}px`,
    background: props.backgroundColor,
    padding: '2px',
    borderRadius: '8px',
    color: props.textColor,
  };
});

export const GameActionButtonContainer = styled.div((props: any) => {
  return {
    width: '100%',
    background: props.background,
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '8px',
    paddingRight: '8px',
    cursor: props.clickable ? 'pointer' : '',
    '@media (hover:hover)': {
      '&:hover': {
        background: props.clickable ? 'var(--block-hover-color)' : '',
      },
    },
  };
});

export const GameActionButton: React.FC<GameActionButtonProps> = observer(
  (props: GameActionButtonProps) => {
    return (
      <GameActionButtonContainer
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          props.onClick && props.onClick();
        }}
        background={props.background}
        size={props.size}
        clickable={!!props.onClick}
      >
        <SvgContainer size={props.size}>
          {props.icon}
          {props.pill && (
            <PillContainer
              size={props.size}
              backgroundColor={props.pill.backgroundColor}
              textColor={props.pill.textColor}
            >
              {props.pill.text}
            </PillContainer>
          )}
        </SvgContainer>
        <TextContainer size={props.size}>{props.text}</TextContainer>
      </GameActionButtonContainer>
    );
  }
);
