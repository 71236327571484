import React, { useEffect, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

export type AdBannerSize =
  | '160x600'
  | '160x600_2'
  | '300x50'
  | '300x50_2'
  | '300x50_3'
  | '300x50_4'
  | '300x50_5'
  | '300x100'
  | '300x100_2'
  | '300x100_3'
  | '300x100_4'
  | '300x100_5'
  | '300x250'
  | '300x600'
  | '300x600_2'
  | '728x90'
  | '970x90'
  | '970x250'
  | '970x250_2';

export interface AddProps {
  size: AdBannerSize;
}

const AD_PREFIX = 'usdoku-com';
const IS_LOCAL = window.location.hostname == 'localhost';
const AD_SIZE_PADDING = 8;

function getAddId(size: string): string {
  return `${AD_PREFIX}_${size}`;
}

export const Ad: React.FC<AddProps> = (props) => {
  const id = getAddId(props.size);

  const [isLoaded, setIsLoaded] = useState(false);
  const size = props.size.split('_')[0];
  const [width, height] = size
    .split('x')
    .map((val) => parseInt(val) + AD_SIZE_PADDING + 'px');

  return (
    <VisibilitySensor
      onChange={(isVisible: boolean) => {
        console.log(`${props.size} Ad is visible`, isVisible);
        if (isVisible && !isLoaded) {
          console.log('Loading Ad', id);
          if (!IS_LOCAL) {
            window.aiptag?.cmd?.display?.push(function () {
              window.aipDisplayTag?.display(id);
            });
          }
          setIsLoaded(true);
        }
      }}
    >
      <div
        style={{
          overflow: 'hidden',
        }}
      >
        <div
          className="flex items-center justify-center"
          id={id}
          style={{
            width,
            height,
            minWidth: width,
            minHeight: height,
            background: 'var(--background-grey-200)',
          }}
        />
      </div>
    </VisibilitySensor>
  );
};
