import React from 'react';

export const Chat = (props: { color: string }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 33 32"
      version="1.1"
      xmlSpace="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {' '}
      <title>Group</title>
      <defs>
        <radialGradient
          cx="282.292024%"
          cy="50%"
          fx="282.292024%"
          fy="50%"
          r="111.21142%"
          gradientTransform="translate(2.822920,0.500000),rotate(180.000000),scale(1.000000,0.959940),translate(-2.822920,-0.500000)"
          id="radialGradient-1"
        >
          <stop stopColor="#FFFFFF" offset="0%"></stop>
          <stop stopColor="#FFFFFF" offset="51.1636801%"></stop>
          <stop stopColor={props.color} offset="100%"></stop>
        </radialGradient>
      </defs>
      <g
        id="Page-Layout"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="iPhone-SE-&amp;-Android-(Smallest)-Copy-2"
          transform="translate(-139.000000, -502.000000)"
        >
          <g id="Group" transform="translate(139.000000, 502.000000)">
            <polygon id="Path" points="0 0 31.2 0 31.2 31.2 0 31.2"></polygon>
            <path
              d="M27.04,18.72 L23.0690909,14.7490909 L13.8036364,14.7490909 C13.0726122,14.7490909 12.48,14.1564787 12.48,13.4254545 L12.48,5.48363636 C12.48,4.75261219 13.0726122,4.16 13.8036364,4.16 L25.7163636,4.16 C26.4473878,4.16 27.04,4.75261219 27.04,5.48363636 L27.04,18.72"
              id="Path"
              stroke="#3D4960"
              strokeWidth="1.3"
              fill={props.color}
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M18.72,19.0981818 L18.72,21.7454545 C18.72,22.4764787 18.1273878,23.0690909 17.3963636,23.0690909 L8.13090909,23.0690909 L4.16,27.04 L4.16,13.8036364 C4.16,13.0726122 4.75261219,12.48 5.48363636,12.48 L8.13090909,12.48"
              id="Path"
              stroke="#3D4960"
              strokeWidth="1.3"
              fill="url(#radialGradient-1)"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <line
              x1="16"
              y1="11"
              x2="24"
              y2="11"
              id="Path"
              stroke="#3D4960"
              strokeWidth="1.3"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
            <line
              x1="9"
              y1="20"
              x2="15"
              y2="20"
              id="Path"
              stroke="#3D4960"
              strokeWidth="1.3"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
            <line
              x1="16"
              y1="8"
              x2="22"
              y2="8"
              id="Path-Copy-2"
              stroke="#3D4960"
              strokeWidth="1.3"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
            <line
              x1="9"
              y1="17"
              x2="12"
              y2="17"
              id="Path-Copy"
              stroke="#3D4960"
              strokeWidth="1.3"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
          </g>
        </g>
      </g>
    </svg>
  );
};
