import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';
import React, { Component } from 'react';

export interface AsyncButtonProps {
  onClick: () => Promise<void>;
  label: string;
  pull?: 'start' | 'center' | 'end';
}

@observer
export class AsyncButton extends Component<AsyncButtonProps> {
  @observable
  private promise?: IPromiseBasedObservable<any>;

  @observable
  private error?: string;

  @action
  private executeAction = () => {
    this.promise = fromPromise(this.executeActionInner());
  };

  @action
  private executeActionInner = async () => {
    try {
      await this.props.onClick();
    } catch (e) {
      runInAction(() => (this.error = e.message));
    }
  };

  render() {
    const promiseRunning = this.promise?.state === 'pending';

    const createButtonClass = this.error
      ? 'bg-blue-500 hover:bg-blue-500'
      : promiseRunning
      ? 'bg-blue-500 hover:bg-blue-700 opacity-50 cursor-not-allowed'
      : 'bg-blue-500 hover:bg-blue-700';

    return (
      <div
        key={`button-${this.props.label}`}
        className={`flex flex-col items-${this.props.pull || 'end'}`}
      >
        <button
          key={`button-${this.props.label}`}
          className={`${createButtonClass} text-white font-bold py-2 px-4 rounded h-10 flex items-center`}
          style={{
            width: 'fit-content',
          }}
          onClick={this.executeAction}
        >
          {promiseRunning && <div className={'loader mr-2'} />}
          {this.props.label}
        </button>
        {this.error && (
          <div className={'flex justify-end mt-2'}>
            <p className="text-red-500 text-xs">{this.error}</p>
          </div>
        )}
      </div>
    );
  }
}
