import React from 'react';

export const Eraser = (props: { eraserColor: string }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 33 32"
      version="1.1"
      xmlSpace="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group</title>
      <g
        id="Page-Layout"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="iPhone-SE-&amp;-Android-(Smallest)"
          transform="translate(-314.000000, -500.000000)"
        >
          <g id="Erase" transform="translate(311.000000, 500.000000)">
            <g id="Group" transform="translate(3.900000, 0.000000)">
              <polygon id="Path" points="0 0 31.2 0 31.2 31.2 0 31.2"></polygon>
              <path
                d="M24.7,24.7 L10.4,24.7 L5.2,19.5 C4.69595933,18.9929513 4.69595933,18.1740487 5.2,17.667 L18.2,4.667 C18.7070487,4.16295933 19.5259513,4.16295933 20.033,4.667 L26.533,11.167 C27.0370407,11.6740487 27.0370407,12.4929513 26.533,13 L14.833,24.7"
                id="Path"
                stroke="#3D4960"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill={props.eraserColor}
              ></path>
              <line
                x1="23.4"
                y1="15.99"
                x2="15.21"
                y2="7.8"
                id="Path"
                stroke="#3D4960"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></line>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
