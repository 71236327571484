import { BoardEntry } from '../Stores/Board';
import { Player } from '../Stores/Store';
import { deviceId } from './deviceId';
import { sessionToken } from './sessionToken';
const { version } = require('../../package.json');

export type ScoringRule = {
  type: 'scoring';
  scores: number[];
  else: number;
};

export type PenaltyRule = {
  type: 'penalty';
  timeout: number;
  scorePenalty: number;
};

export type Rule = PenaltyRule | ScoringRule;

export type GameInfo = {
  status: 'pending' | 'running' | 'completed';
  rules: Rule[];
  supersededBy?: string;
  difficulty: string;
  numberOfPlayers: number;
  mode: string;
  code: string;
  startedAt?: number;
  statusText?: string;
};

export interface GameInfoResponse {
  players: {
    name: string;
    color: string;
  }[];
  info: GameInfo;
}

export interface PublicGamesResponse {
  games: GameInfo[];
}

export interface GameCreateResponse {
  gameCode: string;
}

export interface UpdateVisibilityResponse {
  gameCode: string;
  visibility: string;
}

export type JoinGameResponse = Player & {
  session: {
    token: string;
  };
  nameFlagged: boolean;
};
export type FillBlockReturnType =
  | {
      correct: true;
      scoreAdded: number;
      boardEntry: BoardEntry;
    }
  | {
      correct: false;
      timeout: number;
      penalty: number;
      boardEntry: BoardEntry;
    };
const wait = (ms: number) => new Promise((r) => setTimeout(r, ms));

const retryOperation: any = (
  operation: any,
  delay: number,
  retries: number,
  delayMultiple: number = 2
) =>
  new Promise((resolve, reject) => {
    return operation()
      .then(resolve)
      .catch(({ reason, retry }: { reason: string; retry: boolean }) => {
        if (retry && retries > 0) {
          return wait(delay)
            .then(
              retryOperation.bind(
                null,
                operation,
                delay * delayMultiple,
                retries - 1,
                delayMultiple
              )
            )
            .then(resolve)
            .catch(reject);
        }
        return reject(reason);
      });
  });

export class API {
  private static async fetch<T>(
    url: string,
    method: string,
    body: object,
    retryOptions: { delay: number; count: number; delayMultiple: number } = {
      delay: 1000,
      count: 30,
      delayMultiple: 2,
    }
  ) {
    const doFetch = async () => {
      let result: Response;
      try {
        result = await fetch(url, {
          method,
          body: JSON.stringify(body),
          headers: {
            'X-Usdoku-Pen': deviceId(),
            'Content-Type': 'application/json',
            'X-Usdoku-Version': version,
            'X-Usdoku-Shield': sessionToken() || '',
          },
        });
      } catch {
        throw { reason: 'Failed to fetch', retry: true };
      }

      const json = await result.json();
      const code = result.status;
      if (code >= 400) {
        throw { reason: json.error, retry: false };
      }
      return json as T;
    };
    return await retryOperation(
      doFetch,
      retryOptions.delay,
      retryOptions.count,
      retryOptions.delayMultiple
    );
  }

  static async PublicGames(): Promise<PublicGamesResponse> {
    const result = await this.fetch<PublicGamesResponse>(
      `${window.API_URL}/public`,
      'POST',
      {}
    );
    return result;
  }

  static async Info(gameCode: string): Promise<GameInfoResponse> {
    const result = await this.fetch<GameInfoResponse>(
      `${window.API_URL}/info`,
      'POST',
      {
        gameCode,
      }
    );
    return result;
  }
  static async Chat(
    gameCode: string,
    name: string,
    message: string
  ): Promise<void> {
    const result = await this.fetch<void>(`${window.API_URL}/chat`, 'POST', {
      gameCode,
      name,
      message,
    });
    return result;
  }
  static async Create(
    difficulty: string,
    visibility: string,
    mode: string
  ): Promise<GameCreateResponse> {
    const result = await this.fetch<GameCreateResponse>(
      `${window.API_URL}/create`,
      'POST',
      {
        difficulty,
        visibility,
        mode,
      }
    );
    return result;
  }
  static async UpdateVisibility(
    gameCode: string,
    visibility: string
  ): Promise<UpdateVisibilityResponse> {
    const result = await this.fetch<UpdateVisibilityResponse>(
      `${window.API_URL}/updateVisibility`,
      'POST',
      {
        gameCode,
        visibility,
      }
    );
    return result;
  }

  static async Join(
    gameCode: string,
    name: string,
    isCreator: boolean,
    allowConnectFromOtherDevice: boolean,
    source?: string,
    userSettings?: { notificationsEnabled: boolean; colorsEnabled: boolean }
  ): Promise<JoinGameResponse> {
    return this.fetch<JoinGameResponse>(`${window.API_URL}/join`, 'POST', {
      gameCode,
      name,
      isCreator,
      source,
      userSettings,
      allowConnectFromOtherDevice,
    });
  }

  static async FillBlock(
    gameCode: string,
    name: string,
    position: string,
    value: number
  ): Promise<FillBlockReturnType> {
    return this.fetch<FillBlockReturnType>(
      `${window.API_URL}/fill`,
      'post',
      {
        gameCode,
        name,
        position,
        value,
      },
      { delay: 1000, count: 30, delayMultiple: 1 }
    );
  }

  static async Reset(gameCode: string, name: string): Promise<void> {
    return this.fetch<FillBlockReturnType>(`${window.API_URL}/reset`, 'post', {
      gameCode,
      name,
    });
  }

  static async Start(gameCode: string): Promise<any> {
    return this.fetch(`${window.API_URL}/start`, 'POST', { gameCode });
  }
}
