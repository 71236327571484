export enum Events {
  PlayerFinished = 'PlayerFinished',
  GameSuperseded = 'GameSuperseded',
  Notification = 'Notification',
  Chat = 'Chat',
  DynamicNotifications = 'DynamicNotifications',
}

export interface PlayerFinishedPayload {
  name: string;
}
export interface GameSupersededPayload {
  gameCode: string;
}

export interface NotificationPayload {
  message: string;
}

export interface ChatPayload {
  message: string;
  name: string;
}

export type PlayerDisplay = {
  name: string;
  color: string;
};
export type LeaderUpdateMessage = {
  type: 'leader-update';
  player: PlayerDisplay;
  update: string;
  relativePriority: number; // This is still WIP. Should be [0, 100]
};
export type ScoreAddedMessage = {
  type: 'score-added';
  player: PlayerDisplay;
  value: number;
  relativePriority: number; // This is still WIP. Should be [0, 100]
};
export type PlayerMilestoneUpdateMessage = {
  type: 'player-milestone-update';
  player: PlayerDisplay;
  update: string;
  relativePriority: number;
};
export type GenericUpdateLowPriority = {
  type: 'low-priority-generic-update';
  player: PlayerDisplay;
  update: string;
  relativePriority: number;
};
export type GenericUpdateHighPriority = {
  type: 'high-priority-generic-update';
  player: PlayerDisplay;
  update: string;
  relativePriority: number;
};

export type DynamicNotificationMessage =
  | LeaderUpdateMessage
  | PlayerMilestoneUpdateMessage
  | ScoreAddedMessage
  | GenericUpdateLowPriority
  | GenericUpdateHighPriority;

export type DynamicNotificationsPayload = DynamicNotificationMessage[];
