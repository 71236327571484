import React from 'react';

export const Undo = (props: { undoColor: string | null }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8125 3.09375C16.5957 3.13311 16.398 3.24296 16.25 3.40625L5.34375 14.2813L4.65625 15L5.34375 15.7188L16.25 26.5938C16.3381 26.703 16.4482 26.7926 16.5731 26.8565C16.698 26.9204 16.835 26.9574 16.9752 26.9649C17.1153 26.9724 17.2555 26.9503 17.3866 26.9001C17.5176 26.8499 17.6367 26.7726 17.7359 26.6734C17.8351 26.5742 17.9124 26.4551 17.9626 26.3241C18.0128 26.193 18.0349 26.0528 18.0274 25.9127C18.0199 25.7725 17.9829 25.6355 17.919 25.5106C17.8551 25.3857 17.7655 25.2756 17.6562 25.1875L8.46875 16H24C30.0096 16 34.2437 17.5203 36.9688 19.9688C39.6938 22.4172 41 25.8369 41 30C41 38.3469 37.1562 44.4688 37.1562 44.4688C37.0739 44.5794 37.0154 44.7058 36.9842 44.8402C36.9531 44.9745 36.9501 45.1138 36.9754 45.2494C37.0007 45.3849 37.0538 45.5138 37.1312 45.6278C37.2087 45.7419 37.309 45.8387 37.4257 45.9122C37.5424 45.9857 37.673 46.0342 37.8093 46.0548C37.9457 46.0754 38.0848 46.0675 38.218 46.0317C38.3512 45.996 38.4755 45.933 38.5832 45.8469C38.6909 45.7608 38.7796 45.6533 38.8438 45.5312C38.8438 45.5312 43 38.9631 43 30C43 25.4071 41.4985 21.3313 38.3125 18.4688C35.1265 15.6062 30.3464 14 24 14H8.46875L17.6562 4.8125C17.8134 4.66616 17.9193 4.47312 17.9582 4.26194C17.9971 4.05076 17.9671 3.83266 17.8724 3.6399C17.7778 3.44713 17.6236 3.28995 17.4328 3.19159C17.2419 3.09323 17.0244 3.05893 16.8125 3.09375Z"
        fill={props.undoColor ? props.undoColor : '#3D4960'}
      />
    </svg>
  );
};
