import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';
import React, { Component } from 'react';
import FindSVG from '../../assets/find.svg';
import { SquareLoader } from 'react-spinners';
import { API, GameInfo, PublicGamesResponse } from '../../lib/API';
import { interlace, timeDifference } from '../../lib/util';
import { AsyncButton } from '../../widgets/AsyncButton';
import { Screen } from '../Screen';
import { GAEvent } from '../../lib/GoogleAnalytics';
import { Ad, AdBannerSize } from '../../components/Ad';

export interface LobbyProps {
  goHome: () => void;
  goToJoin: (code: string) => void;
}

@observer
export class Lobby extends Component<LobbyProps> {
  @observable
  private loadingPromise?: IPromiseBasedObservable<void>;

  @observable
  private lastResponse?: PublicGamesResponse;

  @action
  componentDidMount() {
    this.loadingPromise = fromPromise(this.loadGames());
  }

  private loadGames = async () => {
    const result = await API.PublicGames();
    runInAction(() => {
      this.lastResponse = result;
    });
  };

  private clickGame = (code: string) => () => {
    GAEvent('joined_from_lobby');
    return this.props.goToJoin(code);
  };

  private renderLobby = () => {
    const allGames = this.lastResponse!.games;
    const availableGames = allGames.filter((g) => g.numberOfPlayers > 0).length;

    return (
      <div
        className={'w-full px-12 sm-max:px-4'}
        style={{
          maxWidth: '560px',
        }}
      >
        <div className={'flex-col mt-4 flex items-center'}>
          <div className="flex items-center justify-center rounded-sm  px-20 py-12">
            <div className="h-24 w-24">
              <FindSVG />
            </div>
            <div className="flex flex-col justify-center items-left  ml-4">
              <div className="font-bold mb-1">Let's find a game</div>
              <div className={'w-56 lg-max:w-48 text-sm'}>
                There {availableGames === 1 ? 'is' : 'are'}{' '}
                <strong>{availableGames}</strong> available{' '}
                {availableGames === 1 ? 'game' : 'games'} to choose from.
              </div>
              <span className={'mt-2'}>
                <AsyncButton
                  pull={'start'}
                  label={`Refresh`}
                  onClick={async () => {
                    const result = await API.PublicGames();
                    runInAction(() => {
                      this.lastResponse = result;
                    });
                  }}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="mt-4 flex justify-center items-center">
          <Ad size="300x250" />
        </div>
        <div className={'mt-4'}>
          {['easy', 'medium', 'hard', 'extreme'].map((d) => {
            return this.renderSection(d, allGames);
          })}
        </div>
        <div className="mt-4 mb-4 flex justify-center items-center">
          <Ad size="300x100" />
        </div>
        <div
          className="flex flex-col justify-center items-center text-center mt-4 mb-4 p-8 w-full"
          style={{ background: 'var(--background-grey-200' }}
        >
          <div className="font-bold">
            Didn't find the game you were looking for?
          </div>
          <div className={'w-56 lg-max:w-48 text-sm mt-4'}>
            <AsyncButton
              pull={'center'}
              label={`New game`}
              onClick={async () => {
                // NB: this resets the store.
                window.location.href = `/create`;
              }}
            />
          </div>
        </div>
        {navigator.share && (
          <div
            className="flex flex-col justify-center items-center text-center mt-4 mb-4 p-8 w-full"
            style={{ background: 'var(--background-grey-200' }}
          >
            <div className="font-bold">
              Increase your chances of finding available games by sharing UsDoku
              with others.
            </div>
            <div className={'w-56 lg-max:w-48 text-sm mt-4'}>
              <AsyncButton
                pull={'center'}
                label={`Share`}
                onClick={async () => {
                  try {
                    await navigator.share({
                      title: 'UsDoku',
                      url: 'https://usdoku.com?ref=share',
                    });
                    GAEvent('shared_usdoku_success');
                  } catch (error) {
                    GAEvent('shared_usdoku_failure');
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  private renderSection = (
    difficulty: string,
    games: {
      code: string;
      numberOfPlayers: number;
      statusText?: string;
      difficulty: string;
      mode: string;
    }[]
  ) => {
    const relevant = games
      .filter((d) => d.difficulty === difficulty)
      .filter((g) => g.numberOfPlayers > 0)
      .sort((a, b) => {
        return b.numberOfPlayers - a.numberOfPlayers;
      });

    const adSize = (difficulty: string): AdBannerSize => {
      switch (difficulty) {
        case 'easy':
          return '300x50';
        case 'medium':
          return '300x50_2';
        case 'hard':
          return '300x50_3';
        case 'extreme':
          return '300x50_4';
        default:
          return '300x50';
      }
    };

    const interlaced = interlace(
      relevant.map((g) => {
        return (
          <div
            className={
              'p-4 w-full rounded-sm border-gray-400 border-solid cursor-pointer'
            }
            onClick={this.clickGame(g.code)}
            style={{
              borderWidth: '1px',
            }}
          >
            <div className={'flex justify-between'}>
              <span className={'font-semibold'}>{g.code}</span>
              <span className={'flex items-center text-sm'}>
                <i className="ri-user-line"></i> {g.numberOfPlayers} / 10
              </span>
            </div>
            <div>
              <div
                className={'text-sm'}
                style={{ color: 'var(--text-grey-700)' }}
              >
                Game Mode: {titleCase(g.mode)}
              </div>
            </div>
            <div>
              <div
                className={'text-sm'}
                style={{ color: 'var(--text-grey-700)' }}
              >
                {g.statusText}{' '}
              </div>
            </div>
          </div>
        );
      }),
      <div
        style={{
          height: '12px',
        }}
      />
    );

    return (
      <>
        <div className={'mt-4'}>
          <div className={'text-xl font-semibold'}>{titleCase(difficulty)}</div>
        </div>
        <div className={'mt-4'} id={`difficulty-${difficulty}`}>
          {relevant.length > 0 ? (
            interlaced
          ) : (
            <div
              className="flex flex-col justify-center items-center text-center mt-4 mb-4 p-8 w-full"
              style={{ background: 'var(--background-grey-200' }}
            >
              <div className="font-bold">
                There are no {difficulty} games available
              </div>
              <div className={'w-56 lg-max:w-48 text-sm mt-4'}>
                <AsyncButton
                  pull={'center'}
                  label={`Create one`}
                  onClick={async () => {
                    // NB: this resets the store.
                    window.location.href = `/create?difficulty=${difficulty}&visibility=public`;
                  }}
                />
              </div>
            </div>
          )}
          <div
            className={
              'flex flex-col justify-center items-center text-center mt-4 mb-4 w-full'
            }
          >
            <Ad size={adSize(difficulty)} />
          </div>
        </div>
      </>
    );
  };

  render() {
    const { goHome } = this.props;

    return (
      <Screen title={'Lobby'}>
        <div className="flex flex-col items-center sm-max:px-4 w-full">
          <div
            className={'pl-24 md-max:pl-12 sm-max:pl-6 w-full h-full'}
            style={{ background: 'var(--background-grey-100)' }}
          >
            <div
              className="flex items-center text-indigo-400 no-underline hover:no-underline cursor-pointer font-bold text-2xl "
              onClick={goHome}
            >
              UsDoku
            </div>
          </div>
          {this.loadingPromise?.case({
            pending: () => {
              return (
                <div className={'flex flex-col items-center mt-4'}>
                  <SquareLoader color={'#4A90E2'} />
                  <h3 className={'mt-4 font-bold'}>Hang tight</h3>
                  <h4>We're finding some games</h4>
                </div>
              );
            },
            fulfilled: this.renderLobby,
          })}
        </div>
      </Screen>
    );
  }
}

const titleCase = (str: string) => {
  return [str[0].toUpperCase(), str.substring(1).toLowerCase()].join('');
};
