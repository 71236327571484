import React, { Component, FC } from 'react';
import MD5 from 'crypto-js/md5';
import { PlayerIndicator } from './PlayerIndicator';
import { DynamicNotification } from '../Stores/DynamicNotifications';
import { observer } from 'mobx-react';
import './SmartNotificationBar.scss';

import { interlace } from '../lib/util';

export type PlayerDisplay = {
  name: string;
  color: string;
};

export type ScoreUpdateMessage = {
  type: 'scores';
  scores: (PlayerDisplay & { score: number | string })[];
};

export type Payload = ScoreUpdateMessage | DynamicNotification['payload'];

export interface SmartNotificationBarProps {
  message: Payload;
}

export const SmartNotificationBar: FC<SmartNotificationBarProps> = observer(
  (props) => {
    // const MESSAGE_TO_TEST: DynamicNotification['payload'] = {
    //   type: 'low-priority-generic-update',
    //   player: {
    //     color: '#C0FFEE',
    //     name: `Hank`,
    //   },
    //   // value: 10,
    //   update: '[name] is cool!',
    //   relativePriority: 10,
    // };

    const key =
      props.message.type === 'scores'
        ? // HACKY: This avoids the fade-in animation from retriggering when the it goes from podium update -> podium update.
          // But allows us to keep the fade in animation when going from (something else`) -> podium
          'STATIC_VALUE_FOR_SCORES'
        : hashMessage(props.message);

    return (
      <div
        className={'flex w-full justify-center text-sm overflow-auto'}
        style={{
          height: '75px',
          background: 'var(--background-blank)',
        }}
      >
        <div
          key={key}
          className="flex w-full justify-center text-sm overflow-auto dynamic-notification-animation"
          style={{
            background: 'var(--background-blank)',
          }}
        >
          {innerComponent(props.message)}
        </div>
      </div>
    );
  }
);

const trim = (text: string, n: number = 10) => {
  if (text.length <= n) {
    return text;
  }
  return text.substring(0, n - 3) + '...';
};

const innerComponent = (message: Payload) => {
  if (message.type === 'scores') {
    return (
      <div
        className={`container grid items-center`}
        style={{
          gridTemplateColumns:
            'repeat(' +
            message.scores.length +
            ', ' +
            Math.floor(100 / message.scores.length) +
            '%)',
        }}
      >
        {message.scores.map((player, index) => {
          return (
            <div className={'flex font-normal justify-center'}>
              <span className={'mr-2 flex items-center justify-center'}>
                <PlayerIndicator color={player.color} />
              </span>
              <div className="flex flex-wrap flex-col">
                <span className={'overflow-hidden break-all text-sm'}>
                  <strong>{trim(player.name, 20)}</strong>
                </span>
                <span
                  key={hashMessage({ index, score: player.score })}
                  className="dynamic-notification-animation-lite"
                >
                  {withOridnalSuffix(index + 1)} | {player.score}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  } else if (message.type === 'leader-update') {
    return (
      <div className="flex flex-wrap items-center justify-center">
        <span className={`text-blue-600 font-bold mr-1`}>
          {message.update}:
        </span>
        <span className="ml-1">
          <PlayerIndicator
            color={message.player.color}
            playerName={message.player.name}
          />
        </span>
      </div>
    );
  } else if (message.type === 'score-added') {
    let color = '';
    if (message.value < 0) {
      color = 'text-red-600';
    } else {
      color = 'text-blue-600';
    }

    const valueDisplay =
      message.value > 0 ? `+${message.value}` : `${message.value}`;

    return (
      <div className="flex flex-wrap items-center justify-center">
        <span className={`${color} font-bold mr-1`}>{valueDisplay}</span> points
        for
        <PlayerIndicator
          color={message.player.color}
          playerName={message.player.name}
        />
      </div>
    );
  } else if (message.type === 'player-milestone-update') {
    return (
      <div className="flex flex-wrap items-center justify-center">
        <span className="mr-1">
          <PlayerIndicator
            color={message.player.color}
            playerName={message.player.name}
          />
        </span>
        <span className={``}>{message.update}</span>
      </div>
    );
  } else if (
    message.type === 'high-priority-generic-update' ||
    message.type === 'low-priority-generic-update'
  ) {
    // e.g. A cool thing has been done by [name]
    const parts = interlace(
      message.update.split('[name]'),
      // FIXME: PlayerIndicator puts 'ml-2 mr-2' on the color icon, so we don't have to add extra margin-left on the span
      <span className="mr-1">
        <PlayerIndicator
          color={message.player.color}
          playerName={message.player.name}
        />
      </span>
    );
    return (
      <div className="flex flex-wrap items-center justify-center">{parts}</div>
    );
  }
};

function hashMessage(message: any) {
  return MD5(JSON.stringify(message)).toString().substring(16);
}

function withOridnalSuffix(i: number) {
  return `${i}${ordinal_suffix_of(i)}`;
}

function ordinal_suffix_of(i: number) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return 'st';
  }
  if (j == 2 && k != 12) {
    return 'nd';
  }
  if (j == 3 && k != 13) {
    return 'rd';
  }
  return 'th';
}
