import React, { FC } from 'react';

export interface PlayerIndicatorProps {
  playerName?: string;
  color: string;
  fontSize?: 'small' | 'medium' | 'large';
  fontWeight?: 'normal' | 'bold';
}

export const PlayerIndicator: FC<PlayerIndicatorProps> = (props) => {
  const { color, playerName, fontSize = 'medium', fontWeight = 'bold' } = props;
  return (
    <>
      <div
        className={`flex items-center ${fontWeightClass(
          fontWeight
        )} ${fontSizeClass(fontSize)}`}
      >
        <div
          className={'ml-2 mr-2'}
          style={{
            backgroundColor: color,
            height: '24px',
            width: '24px',
            borderRadius: '2px',
          }}
        />{' '}
        <strong className={'flex-grow'}>{playerName}</strong>
      </div>
    </>
  );
};

function fontSizeClass(fontSize: 'small' | 'medium' | 'large') {
  if (fontSize === 'small') {
    return 'text-sm';
  }
  if (fontSize === 'medium') {
    return 'text-md';
  }
  return 'text-lg';
}

function fontWeightClass(fontWeight: 'bold' | 'normal') {
  if (fontWeight === 'bold') {
    return 'font-bold';
  }
  return 'font-normal';
}
