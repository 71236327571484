import React, { FC } from 'react';

export interface SendChatViewProps {
  options: string[];
  onSelect: (option: string) => void;
  onClose: () => void;
}

export const SendChatView: FC<SendChatViewProps> = (props) => {
  const onClick = (item: string) => () => {
    return props.onSelect(item);
  };

  return (
    <div
      className={'p-4'}
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflow: 'auto',
        maxHeight: '600px',
      }}
    >
      <div className={'flex justify-between'}>
        <div className={'font-bold mb-4'}>Send a message</div>
        <div
          className={'text-sm cursor-pointer hover:underline'}
          style={{ color: 'var(--text-grey-700)' }}
          onClick={props.onClose}
        >
          Close
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {props.options.map((o, i) => {
          return (
            <div
              key={`option-${i}`}
              onClick={onClick(o)}
              className={
                'cursor-pointer hover:bg-orange-200 border-solid  text-sm border-orange-600 text-orange-600'
              }
              style={{
                borderWidth: '1px',
                padding: '8px',
                borderRadius: '2px',
                width: 'fit-content',
                marginBottom: '8px',
                marginRight: '8px',
              }}
            >
              {o}
            </div>
          );
        })}
      </div>
    </div>
  );
};
