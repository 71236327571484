import React from 'react';

export const Pencil = (props: { pencilFillColor: string }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 33 32"
      version="1.1"
      xmlSpace="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Pencil Icon</title>
      <g
        id="Page-Layout"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="iPhone-SE-&amp;-Android-(Smallest)"
          transform="translate(-240.000000, -499.000000)"
        >
          <g id="Notes" transform="translate(235.000000, 499.000000)">
            <g id="Pencil-Icon" transform="translate(5.850000, 0.000000)">
              <polygon id="Path" points="0 0 31.2 0 31.2 31.2 0 31.2"></polygon>
              <path
                fill={props.pencilFillColor}
                d="M5.2,26 L10.4,26 L24.05,12.35 C25.4859403,10.9140596 25.4859403,8.58594037 24.05,7.15000004 C22.6140596,5.7140597 20.2859404,5.71405969 18.85,7.15 L5.2,20.8 L5.2,26"
                id="Path"
                stroke="#3D4960"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <line
                x1="17.55"
                y1="8.45"
                x2="22.75"
                y2="13.65"
                id="Path"
                stroke="#3D4960"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></line>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
