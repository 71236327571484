import {
  IValueDidChange,
  computed,
  observable,
  observe,
  runInAction,
} from 'mobx';
import { w3cwebsocket as WSClient } from 'websocket';
import io from 'socket.io-client';
import { BoardStore, BoardEntry, OpenBoardEntryFields } from './Board';
import { EventEmitter } from 'events';
import { Events } from './Events';
import { KVStorageEngine } from '../lib/Engines/KVStorageEngine';
import { flatten } from 'ramda';
import { SettingsStore } from './SettingsStore';
import { API } from '../lib/API';
import { DynamicNotificationsStore } from './DynamicNotifications';
import { GAEvent } from '../lib/GoogleAnalytics';

export interface Score {
  reason: string;
  value: number;
}

export interface Player {
  name: string;
  color: string;
  score: Score[];
  isCreator?: boolean;
  completedAt?: number;
  joinedAt?: number;
}

export class Store {
  @observable gameCode: string;
  @observable players: Player[];
  @observable recentGameCode: string;
  @observable isConnected: boolean = false;
  @observable isCreator: boolean = false;
  @observable status: 'pending' | 'running' | 'completed' | 'starting' =
    'pending';
  @observable timeToGameStart = 3;
  @observable playerName: string;
  @observable hideCompleted: boolean;
  @observable visibility: 'private' | 'public' = 'private';
  @observable mode: 'original' | 'hardcore' = 'original';
  @observable difficulty: string;
  @observable startedAt: number;
  @observable validChatOptions: {
    text: string;
    where: 'all' | 'lobby' | 'game';
  }[] = [];
  boardStore: BoardStore;
  settingsStore: SettingsStore;
  dynamicNotificationStore: DynamicNotificationsStore;

  events: EventEmitter = new EventEmitter();

  private forceWSClosed = false;

  private client: WSClient;
  private socketIOClient: any;
  private messageBuffer: string[];
  private hasRegistered: boolean;

  constructor() {
    this.boardStore = new BoardStore(this);
    this.settingsStore = new SettingsStore();
    this.dynamicNotificationStore = new DynamicNotificationsStore();
    this.playerName = '';
    this.recentGameCode = '';

    this.messageBuffer = [];
    this.hasRegistered = false;
    // this.client = this.setupWebsocket();
    this.socketIOClient = this.setupSocketIo();
    this.startedAt = 0;

    this.players = [];
    this.hideCompleted = false;

    this.gameCode = '';

    observe(
      this.settingsStore,
      'darkMode',
      (change: IValueDidChange<boolean>) => {
        document.body.classList.remove('light');
        document.body.classList.remove('dark');
        const theme = change.newValue ? 'dark' : 'light';
        document.body.classList.add(theme);
        if (change.newValue == true) {
          GAEvent('dark_mode_enabled');
        } else {
          GAEvent('dark_mode_disabled');
        }
      }
    );
  }

  @computed get theme() {
    return this.settingsStore.darkMode ? 'dark' : 'light';
  }

  get gameLink() {
    return new URL(this.gameCode, window.location.origin).href;
  }

  get shareGameLink() {
    return `${this.gameLink}?ref=share`;
  }

  get homePageLink() {
    return new URL('/', window.location.origin).href;
  }

  get shareHomePageLink() {
    return `${this.homePageLink}?ref=share`;
  }

  async init() {
    this.playerName = (await KVStorageEngine.getItem('playerName')) || '';
    this.recentGameCode =
      (await KVStorageEngine.getItem('recentGameCode')) || '';
    await this.settingsStore.load();
  }

  closeSocket() {
    this.forceWSClosed = true;
    if (this.client && this.client.readyState == WSClient.OPEN) {
      this.client.close();
    }
  }

  registerEvent(event: Events, payload: object) {
    this.events.emit(event, payload);
  }

  setupSocketIo(): any {
    const socket = io(window.WS_URL);
    socket.on('data', (data) => {
      runInAction(() => (this.isConnected = true));
      if (data.type === 'event') {
        this.registerEvent(data.topic as Events, data.payload);
      }
      if (data.board) {
        this.boardStore.setBoard(data.board);
      }
      if (data.players) {
        this.players = data.players;
      }
      if (data.validChatMessages) {
        this.validChatOptions = data.validChatMessages;
      }
      if (data.info) {
        runInAction(async () => {
          if (
            this.status == 'pending' &&
            data.info.status == 'running' &&
            (await KVStorageEngine.getItem('gameHasStartedCode')) !=
              this.gameCode
          ) {
            this.status = 'starting';
            await KVStorageEngine.setItem('gameHasStartedCode', this.gameCode);
            let startingInterval = setInterval(() => {
              this.timeToGameStart -= 1;
              if (this.timeToGameStart < 0) {
                this.status = 'running';
                clearInterval(startingInterval);
              }
            }, 1000);
          } else {
            this.status = data.info.status;
          }
          if (this.startedAt == 0) {
            KVStorageEngine.setItem('visibility', data.info.visibility);
            KVStorageEngine.setItem('difficulty', data.info.difficulty);
            KVStorageEngine.setItem('mode', data.info.mode);
          }
          this.startedAt = data.info.startedAt;
          this.visibility = data.info.visibility;
          this.mode = data.info.mode;
          this.difficulty = data.info.difficulty;
        });
      }
    });

    socket.on('close', () => {
      this.client = this.setupSocketIo();
      if (this.hasRegistered == true) {
        this.connectToGame();
      }
    });

    socket.io.on('reconnect', (attempt) => {
      if (this.hasRegistered === true) {
        this.connectToGame();
      }
    });

    return socket;
  }

  @computed
  get canStartGame() {
    return this.visibility === 'public' || this.isCreator;
  }

  setupWebsocket(): WSClient {
    if (this.forceWSClosed) {
      throw new Error('Websocket forced closed. Not re-opening');
    }
    let client = new WSClient(window.WS_URL);

    client.onopen = () => {
      while (this.messageBuffer.length > 0) {
        let message = this.messageBuffer.shift();
        if (message) {
          this.client.send(message);
        }
      }
    };

    client.onmessage = (e) => {
      runInAction(() => (this.isConnected = true));
      if (typeof e.data === 'string') {
        let data = JSON.parse(e.data);
        if (data.type === 'event') {
          this.registerEvent(data.topic as Events, data.payload);
        }
        if (data.board) {
          this.boardStore.setBoard(data.board);
        }
        if (data.players) {
          this.players = data.players;
        }
        if (data.validChatMessages) {
          this.validChatOptions = data.validChatMessages;
        }
        if (data.info) {
          runInAction(async () => {
            if (
              this.status == 'pending' &&
              data.info.status == 'running' &&
              (await KVStorageEngine.getItem('gameHasStartedCode')) !=
                this.gameCode
            ) {
              this.status = 'starting';
              await KVStorageEngine.setItem(
                'gameHasStartedCode',
                this.gameCode
              );
              let startingInterval = setInterval(() => {
                this.timeToGameStart -= 1;
                if (this.timeToGameStart < 0) {
                  this.status = 'running';
                  clearInterval(startingInterval);
                }
              }, 1000);
            } else {
              this.status = data.info.status;
            }
            this.startedAt = data.info.startedAt;
            this.visibility = data.info.visibility;
            this.mode = data.info.mode;
            this.difficulty = data.info.difficulty;
          });
        }
      }
    };

    client.onclose = (e) => {
      console.log('socket closed', e);
      this.client = this.setupWebsocket();
      if (this.hasRegistered == true) {
        this.connectToGame();
      }
    };

    client.onerror = (e) => {
      console.log('socket error', e);
    };
    return client;
  }

  connectToGame() {
    console.log(
      `[socket] Connecting to game ${this.gameCode} for ${this.playerName}`
    );
    this.hasRegistered = true;
    this.sendSocketMessage('register', {
      name: this.playerName,
      gameCode: this.gameCode,
      type: 'register',
    });
  }

  setGameCode(gameCode: string) {
    KVStorageEngine.setItem('recentGameCode', gameCode);
    this.recentGameCode = gameCode;
    this.gameCode = gameCode;
  }

  setPlayerName(playerName: string) {
    this.playerName = playerName;
    KVStorageEngine.setItem('playerName', playerName);
  }

  sendSocketMessage(key: string, message: any) {
    this.socketIOClient.emit(key, message);

    // if (this.client.readyState === 1) {
    //   // this.client.send(message);
    //   this.socketIOClient.emit(key, message);
    // } else {
    //   this.messageBuffer.push(message);
    // }
  }

  async resetGame() {
    await API.Reset(this.gameCode, this.currentPlayer!.name);

    this.boardStore.clearBoard();
  }

  @computed get playerGuessPercentages(): (Pick<
    Player,
    'color' | 'name' | 'completedAt'
  > & {
    percentage: number;
  })[] {
    const openEntries = this.boardStore.boardEntries.filter(
      (b) => b.type == 'open'
    ) as OpenBoardEntryFields[];
    const flattenedGuesses = flatten(
      openEntries.map((entry) => entry.guesses).filter((g) => g.length > 0)
    );

    return this.players
      .map((player) => {
        const numberOfEntries = flattenedGuesses.filter(
          (entry) => entry.name == player.name
        ).length;
        const percentage = Math.floor(
          (numberOfEntries / openEntries.length) * 100
        );

        return {
          percentage,
          name: player.name,
          color: player.color,
          completedAt: player.completedAt,
        };
      })
      .sort((a, b) => {
        if (a.completedAt && b.completedAt) {
          return a.completedAt - b.completedAt; // smallest wins
        } else if (a.completedAt) {
          return -1;
        } else if (b.completedAt) {
          return 1;
        }
        return b.percentage - a.percentage;
      });
  }

  @computed get playerScores(): (Pick<Player, 'color' | 'name'> & {
    score: number;
  })[] {
    return this.players
      .map((player) => {
        const score = player.score
          .map((s) => s.value)
          .reduce((memo, x) => memo + x, 0);
        return {
          score,
          name: player.name,
          color: player.color,
        };
      })
      .sort((a, b) => {
        return b.score - a.score;
      });
  }

  @computed get completedPlayers(): string[] {
    let completed: string[] = [];

    this.players.forEach((player: Player) => {
      let hasUnanswered = this.boardStore.boardEntries.some(
        (entry: BoardEntry) => {
          return entry.type == 'open' && entry.scorers.indexOf(player.name) < 0;
        }
      );
      if (!hasUnanswered) {
        completed.push(player.name);
      }
    });

    return completed;
  }

  @computed
  get myColor() {
    if (!this.playerName || this.players.length === 0) {
      return undefined;
    }
    return this.players.find((p) => p.name === this.playerName)?.color;
  }

  get currentPlayer(): Player | undefined {
    return this.players.find((player) => {
      return player.name == this.playerName;
    });
  }

  static getPlayerScore(player: Player | undefined): number {
    let playerScore = 0;
    if (!player) {
      return 0;
    }
    player.score.forEach((score: Score) => {
      playerScore += score.value;
    });
    return playerScore;
  }

  @computed
  get currentPlayerScore() {
    if (!this.currentPlayer) {
      return 0;
    }
    if (this.mode == 'original') {
      const playerScore = this.playerScores.find(
        (ps) => ps.name == this.currentPlayer!.name
      );
      if (!playerScore) {
        return 0;
      }
      return playerScore.score;
    } else if (this.mode == 'hardcore') {
      const playerScore = this.playerGuessPercentages.find(
        (pgp) => pgp.name == this.currentPlayer!.name
      );
      if (!playerScore) {
        return 0;
      }
      return playerScore.percentage;
    }
    return 0;
  }
}
