import React, { FC } from 'react';
import { PlayerIndicator } from '../components/PlayerIndicator';

export interface ChatToastProps {
  message: string;
  name: string;
  color: string;
}
export interface NameFlaggedToastProps {
  name: string;
}
export const ChatToast: FC<ChatToastProps> = ({ message, name, color }) => {
  return (
    <div className={'flex flex-col'}>
      <span className={'mb-2 font-bold text-black'}>{message}</span>
      <span className={'text-sm flex items-center'}>
        From <PlayerIndicator color={color || 'white'} playerName={name} />
      </span>
    </div>
  );
};
export const NameFlaggedToast: FC<NameFlaggedToastProps> = ({ name }) => {
  return (
    <div className={'flex flex-col'}>
      <span className={'mb-2 font-bold text-black'}>You are now {name}</span>
      <span className={'text-sm flex items-center'}>
        The name you have selected was flagged as inappropriate. If you think
        this is a mistake, please reach out to us on Discord.
      </span>
    </div>
  );
};
