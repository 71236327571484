import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';

export type BoardEntryState = 'closed' | 'empty' | 'entered';

export interface MiniSudokuIndicatorProps {
  boardStates: BoardEntryState[];
  playerColor: string;
}

export const MiniSudoku = styled.div((props: any) => {
  return {
    background: props.background,
    width: props.size,
    height: props.size, 
    maxWidth: props.size,
    maxHeight: props.size,
    overflow: 'visible',
    userSelect: 'none',
    display: 'grid',
    gridTemplateColumns: 'repeat(9, 1fr)',
    boxSizing: 'content-box',
  };
});

export const MiniBlock = styled.div((props: any) => {
  return {
    background: props.background,
    width: props.size,
    height: props.size,
    maxWidth: props.size,
    maxHeight: props.size,
    overflow: 'hidden',
    userSelect: 'none',
    borderTop: props.borders?.top,
    borderBottom: props.borders?.bottom,
    borderRight: props.borders?.right,
    borderLeft: props.borders?.left,
    boxSizing: 'content-box',
  };
});


function getMiniBlockColour(boardEntryState: BoardEntryState, colour: string) {
  switch(boardEntryState) {
    case 'closed':
      return 'transparent'; //'var(--mini-block-closed-color)';
    case 'empty':
      return 'transparent';
    case 'entered':
      return colour;
    default: return ''
  }
}

function getMiniBlockBorder(index: number, borderColor: string) {
  let left = `0.5px solid ${borderColor}`;
  let right = undefined;
  let top = `0.5px solid ${borderColor}`;
  let bottom = undefined;
  if (index % 9 == 0) {
    left = `1px solid ${borderColor}}`;
  }
  if ((index + 1) % 9 == 0) {
    right = `1px solid ${borderColor}`;
  }
  if (index % 9 == 2 || index % 9 == 5) {
    right = `0.5px solid ${borderColor}`;
  }

  if ((index >= 18 && index < 27) || (index >= 45 && index < 54)) {
    bottom = `1px solid ${borderColor}`;
  }
  if (index < 9) {
    top = `1px solid ${borderColor}}`;
  }
  if (index > 71) {
    bottom = `1px solid ${borderColor}`;
  }
  return {left, right, top, bottom}
}

function getMiniSudokuBlocks(boardEntryStates: BoardEntryState[], colour: string) {
  return boardEntryStates.map((boardEntryState, index) => {
    const backgroundColor = getMiniBlockColour(boardEntryState, colour);
    return <MiniBlock borders={getMiniBlockBorder(index, 'grey')} key={index} background={backgroundColor} size={6}></MiniBlock>
  })

}

export const MiniSudokuIndicator: React.FC<MiniSudokuIndicatorProps> = observer((props) => {
  return (
    <MiniSudoku background={'transparent'} size={54}>
      {getMiniSudokuBlocks(props.boardStates, props.playerColor)}
    </MiniSudoku>
  );
});
