import * as React from 'react';
import styled from '@emotion/styled';
import { SmallBlock } from './SmallBlock';
import { BoardEntry, BoardStore, Position } from '../Stores/Board';
import { observer } from 'mobx-react';
import Color from 'color';
import { Store } from '../Stores/Store';

export interface SudokuGridBlockProps {
  background: string;
  size: number; // square
  boardState: { [key: string]: BoardEntry };
  mode: string;
  onBlockSelect?: (block: string) => void;
  selectedBlock?: string;
  pencilMarksState?: { [key: string]: number[] };
  currentPlayerName?: string;
  dark?: boolean;
  // User defined settings
  userSetting_swicthOffAllColors?: boolean;
}

export const SudokuGridBlock = styled.div((props: any) => {
  return {
    background: props.background,
    width: props.size,
    height: props.size,
    maxWidth: props.size,
    maxHeight: props.size,
    overflow: 'visible',
    userSelect: 'none',
    display: 'grid',
    gridTemplateColumns: 'repeat(9, 1fr)',
    border: '2px solid var(--border-outer)',
    marginTop: '8px',
    boxSizing: 'content-box',
  };
});

function getBlockBackground(
  props: SudokuGridBlockProps,
  boardPosition: string
): string {
  if (boardPosition == props.selectedBlock) {
    return 'var(--block-selected-color)';
  }
  if (props.userSetting_swicthOffAllColors) {
    // User is boring, user doesn't want to see colors
    return '';
  }
  if (props.mode == 'hardcore') {
    return '';
  }
  let boardEntry = props.boardState[boardPosition];
  if (boardEntry) {
    if (boardEntry.type == 'open') {
      if (boardEntry.scorers.length > 0) {
        if (!boardEntry.backgroundColor) {
          return '';
        }
        let backgroundLighten = new Color(
          boardEntry.backgroundColor || 'white'
        );
        if (props.dark) {
          // backgroundLighten = backgroundLighten.lighten(0.1);
          return `radial-gradient(circle, ${
            boardEntry.backgroundColor
          } 0%, ${backgroundLighten.hex()} 70.94%)`;
        }

        backgroundLighten = backgroundLighten.lighten(0.11);
        return `radial-gradient(circle, ${
          boardEntry.backgroundColor
        } 0%, ${backgroundLighten.hex()} 99.94%)`;
      }
    } else if (boardEntry.type == 'guess') {
      return boardEntry.backgroundColor;
    }
  }
  return '';
}

function getHardcoreBlockColor(blockState: BoardEntry, dark?: boolean) {
  if (blockState.type == 'open') {
    return 'var(--usdoku-purple)';
  } else if (blockState.type == 'guess') {
    return 'var(--number-pending-color)';
  }
}

function getOriginalBlockColor(
  blockState: BoardEntry,
  dark?: boolean,
  userSetting_swicthOffAllColors?: boolean
) {
  if (blockState.type == 'guess') {
    if (blockState.value != blockState.guess) {
      return 'var(--number-incorrect-color)';
    } else {
      return 'var(--number-pending-color)';
    }
  }
  if (blockState.type == 'closed') {
    return;
  }
  // Specific case for original with dark mode and scorer
  const hasScorer = blockState.scorers.length > 0;
  if (
    dark &&
    hasScorer &&
    blockState.backgroundColor &&
    !userSetting_swicthOffAllColors
  ) {
    return 'var(--text-grey-500)';
  }
}

function getBlockColor(
  blockState: BoardEntry,
  mode: string,
  dark?: boolean,
  userSetting_swicthOffAllColors?: boolean
): string | undefined {
  if (!blockState) {
    return '';
  }
  if (mode == 'hardcore') {
    return getHardcoreBlockColor(blockState, dark);
  } else if (mode == 'original') {
    return getOriginalBlockColor(
      blockState,
      dark,
      userSetting_swicthOffAllColors
    );
  }
}

function getBlockValue(props: SudokuGridBlockProps, blockState: BoardEntry) {
  if (!blockState) return undefined;

  if (blockState.type == 'closed') {
    return blockState.value;
  } else if (blockState.type == 'open') {
    if (
      props.currentPlayerName &&
      blockState.scorers.includes(props.currentPlayerName)
    ) {
      return blockState.value;
    }
    if (blockState.guesses.length > 0) {
      const guess = blockState.guesses.find(
        (g) => g.name == props.currentPlayerName
      );
      if (guess) {
        return guess.value;
      }
    }
  } else if (blockState.type == 'guess') {
    return blockState.guess;
  }
  return undefined;
}

enum RelationToBlock {
  left,
  right,
  top,
  bottom,
  none,
}

function isInRelationToBlock(
  position: Position,
  target?: Position
): RelationToBlock {
  if (!target) {
    return RelationToBlock.none;
  }
  if (position.column != target.column && position.row != target.row) {
    return RelationToBlock.none;
  }

  if (position.column == target.column) {
    let diff = position.row - target.row;
    if (diff == 1) {
      return RelationToBlock.bottom;
    } else if (diff == -1) {
      return RelationToBlock.top;
    }
  } else if (position.row == target.row) {
    let diff = position.column - target.column;
    if (diff == 1) {
      return RelationToBlock.right;
    } else if (diff == -1) {
      return RelationToBlock.left;
    }
  }

  return RelationToBlock.none;
}

function getGridBlocks(props: SudokuGridBlockProps) {
  let gridBlocks = [];
  let thinBorderThickness = 1;
  let thickBorderThickness = 1;
  let lighterColor = 'var(--border-inner)';
  let darkerColor = 'var(--border-outer)';

  for (let row = 1; row <= 9; row++) {
    for (let column = 1; column <= 9; column++) {
      let blockPosition = BoardStore.getPositionString({ row, column });
      let thinBorderColor = lighterColor;
      let thickBorderColor = darkerColor;
      let borders: any = {
        top: `${thinBorderThickness}px solid ${thinBorderColor}`,

        left: `${thinBorderThickness}px solid ${thinBorderColor}`,
      };
      if (column == 3 || column == 6) {
        borders.right = `${thickBorderThickness}px solid ${thickBorderColor}`;
      }
      if (column == 4 || column == 7) {
        borders.left = `${thickBorderThickness}px solid ${thickBorderColor}`;
      }
      if (row == 3 || row == 6) {
        borders.bottom = `${thickBorderThickness}px solid ${thickBorderColor}`;
      }
      if (row == 4 || row == 7) {
        borders.top = `${thickBorderThickness}px solid ${thickBorderColor}`;
      }
      if (column == 1) {
        borders.left = 'none';
      }
      if (column == 9) {
        borders.right = 'none';
      }
      if (row == 1) {
        borders.top = 'none';
      }
      if (row == 9) {
        borders.bottom = 'none';
      }
      let selectedValue;
      if (props.selectedBlock) {
        let selectedBlock = props.boardState[props.selectedBlock];
        if (selectedBlock.type == 'open' && props.currentPlayerName) {
          if (props.mode == 'original') {
            if (
              BoardStore.playerHasScoredForBlock(
                props.currentPlayerName,
                selectedBlock
              )
            ) {
              selectedValue = selectedBlock.value;
            }
          } else {
            const guess = selectedBlock.guesses.find(
              (g) => g.name == props.currentPlayerName
            );
            if (guess) {
              selectedValue = guess.value;
            }
          }
        } else if (selectedBlock.type == 'closed') {
          selectedValue = selectedBlock.value;
        }
      }
      let blockState = props.boardState[blockPosition];
      let pencilMarks = props.pencilMarksState
        ? props.pencilMarksState[blockPosition]
        : [];
      gridBlocks.push(
        <SmallBlock
          size={
            (props.size - thickBorderThickness * 4 - thinBorderThickness * 6) /
            9
          }
          key={blockPosition}
          onSelect={() => {
            props.onBlockSelect && props.onBlockSelect(blockPosition);
          }}
          background={getBlockBackground(props, blockPosition)}
          borders={borders}
          selectedValue={selectedValue}
          value={getBlockValue(props, blockState)}
          pencilMarks={pencilMarks}
          color={getBlockColor(
            blockState,
            props.mode,
            props.dark,
            props.userSetting_swicthOffAllColors
          )}
          dark={props.dark}
        ></SmallBlock>
      );
    }
  }
  return gridBlocks;
}

export const SudokuGrid: React.FC<SudokuGridBlockProps> = observer((props) => {
  return (
    <SudokuGridBlock background={props.background} size={props.size}>
      {getGridBlocks(props)}
    </SudokuGridBlock>
  );
});
