import React, { FC } from 'react';
import { render } from 'react-dom';
import { QueryParamProvider } from 'use-query-params';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from 'react-router-dom';

import { GameWidget } from './widgets/GameWidget';
import 'react-toastify/dist/ReactToastify.css';
import 'reactjs-popup/dist/index.css';
import 'remixicon/fonts/remixicon.css';

import { Store } from './Stores/Store';
import { Join } from './Screens/Join/Join';
import { Create } from './Screens/Create/Create';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Landing } from './Screens/Landing/Landing';
import { StartingScreen } from './Screens/Starting/Starting';
import { setupCapacitor } from './setup_capacitor';
import 'fontsource-montserrat/400';
import 'fontsource-montserrat/600';
import 'fontsource-montserrat/700';
import 'fontsource-source-sans-pro/400';
import 'fontsource-source-sans-pro/600';
import { Lobby } from './Screens/Lobby/Lobby';
import { Privacy } from './Screens/Privacy/Privacy';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

declare global {
  interface Window {
    API_URL: string;
    WS_URL: string;
    MAINTENANCE_IN_PROGRESS: boolean;
    aiptag: any;
    aipDisplayTag: any;
  }
}

let store: Store = new Store();
(window as any).store = store;

function Game(props: any) {
  return (
    <GameWidget
      key="gameWidget"
      store={store}
      onBack={() => {
        window.location.href = '/';
      }}
    ></GameWidget>
  );
}

const GameHandler: FC<{ history: any }> = observer(({ history }) => {
  if (store.status === 'pending') {
    return <JoinScreenBuilder key="joinScreenBuilder" history={history} />;
  }
  if (store.status === 'running') {
    return <Game key="game" history={history} />;
  }
  if (store.status == 'starting') {
    return (
      <StartingScreen
        goHome={() => {
          window.location.href = '/';
        }}
        timeToGameStart={store.timeToGameStart}
      />
    );
  }
  return <div>Unknown status</div>;
});

const JoinScreenBuilder: FC<{ history: any }> = ({ history }) => {
  const { gameId } = useParams<{ gameId: string }>();
  return (
    <Join
      store={store}
      code={gameId}
      goHome={() => {
        window.location.href = '/';
      }}
    />
  );
};

const App = observer(() => {
  return (
    <Router>
      <QueryParamProvider>
        <div
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/create" key="createRoute">
              {(config) => {
                return (
                  <Create
                    key="create"
                    goToJoin={(code) => {
                      runInAction(() => (store.isCreator = true));
                      config.history.push(`/${code}`);
                    }}
                    goHome={() => {
                      window.location.href = '/';
                    }}
                  />
                );
              }}
            </Route>
            <Route path="/lobby" key="lobbyRoute">
              {(config) => {
                return (
                  <Lobby
                    goHome={() => {
                      window.location.href = '/';
                    }}
                    goToJoin={(code) => {
                      config.history.push(`/${code}?source=lobby`);
                    }}
                  />
                );
              }}
            </Route>
            <Route path="/privacy" key="privacyRoute">
              {(config) => {
                return (
                  <Privacy
                    goHome={() => {
                      window.location.href = '/';
                    }}
                  />
                );
              }}
            </Route>
            <Route path="/:gameId" key="gameIdRoute">
              {(config) => {
                return (
                  <GameHandler key="gameHandler" history={config.history} />
                );
              }}
            </Route>
            <Route path="/" key="homeRoute">
              {(config) => {
                return (
                  <Landing
                    mostRecentGameCode={store.recentGameCode}
                    onFind={() => {
                      config.history.push('/lobby');
                    }}
                    onCreate={() => {
                      config.history.push('/create');
                    }}
                    onJoin={(code) => {
                      config.history.push(`/${code}`);
                    }}
                    settingsStore={store.settingsStore}
                  />
                );
              }}
            </Route>
          </Switch>
        </div>
      </QueryParamProvider>
    </Router>
  );
});

window.addEventListener('load', async () => {
  // This ensures that SourceSans has been loaded so that we don't get a jump in fonts when going to the game screen
  let preload = document.getElementById('preload');
  preload?.setAttribute('style', 'display: none;');
  await store.init();
  render(<App />, document.getElementById('app'));

  await setupCapacitor();
});
